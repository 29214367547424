import { Home, School } from "@mui/icons-material";

// constant
const icons = { Home, School };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const pages = {
  id: 'basic-pages',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: icons.Home
    },
    {
      id: 'schools',
      title: 'Schools',
      type: 'item',
      url: '/@schools',
      icon: icons.School
    }
  ]
};

export default pages;