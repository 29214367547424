import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useApi } from "../providers/ApiProvider";
import { Presentation } from "../modules";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AppCarousel from "./AppCarousel";
import PresentationItem from "./PresentationItem";
import {ChevronRight, ChevronLeft} from '@mui/icons-material';
import Carousel from "react-multi-carousel";

const TopPresentations = () => {
    const api = useApi();
    const [presentations, setPresentations] = useState<Array<Presentation>>([]);
    const { t } = useTranslation();
    const carousel = useRef<Carousel>(null);
    const theme = useTheme();

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    useEffect(() => {
        if( !presentations.length ) {
            api.getPublicPresentations(1, 8).then((response) => {
                setPresentations(response.data)
            }).catch(() => {

            })
        }
    }, []);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{display: "flex", flexDirection: "row"}}>
                <Typography variant="h6" sx={{marginBottom: "1rem", fontWeight: "900"}} color={'primary.dark'} children={t('Top presentations')}/>
                <Box sx={{marginInlineStart: "auto"}}>
                    <IconButton onClick={() => carousel.current?.previous(1)}>
                        <ChevronLeft />
                    </IconButton>
                    <IconButton onClick={() => carousel.current?.next(1)}>
                        <ChevronRight />
                    </IconButton>
                </Box>
            </Box>
            <AppCarousel ref={carousel} infinite responsive={responsive} arrows={false}>
                {presentations.map((presentation, index) => (
                    <Box component={'div'} sx={{padding: "1rem"}} key={index}>
                        <PresentationItem {...presentation} key={index}/>
                    </Box>
                ))}
            </AppCarousel>
        </Box>
    )
}

export default TopPresentations;