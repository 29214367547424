import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import {CloudUploadOutlined, Close as CloseIcon} from '@mui/icons-material';
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next';
import { Alert, Avatar, Box, Card, CardContent, CircularProgress, Grid, Paper, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import {importFileandPreview, generateVideoThumbnails} from '../services/VideoService'
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth } from '../providers/AuthProvider';
import Moment from 'react-moment';
import ActivitySelect from './ActivitySelect';
import { Link } from 'react-router-dom';
import PresentationBox from './PresentationBox';
import VideoPlayer from './VideoPlayer';
import {b64toBlob} from '../helpers'
import { useApi } from '../providers/ApiProvider';
import LinearProgress from './LinearProgress'
import { Presentation } from '../modules';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export interface PresentationModalProps {
    children?: React.ReactNode;
    onClose: () => void;
    open: boolean;
}

interface VideoFile {
    file?: File
}

export default function PresentationModal({children, onClose, open} : PresentationModalProps) {
    const [dragEnter, setDragEnter] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [added, setAdded] = React.useState(false);
    const [preview, setPreview] = React.useState('');
    const [error, setError] = React.useState<string>('');
    const [thumbs, setThumbs] = React.useState<Array<string>>([]);
    const [thumbSelected, setThumbSelected] = React.useState(0);
    const [percent, setPercent] = React.useState(0);
    const [tried, setTried] = React.useState(0);
    const [activeStep, setActiveStep] = React.useState(0);
    const [video, setVideo] = React.useState<VideoFile>({});
    const [presentation, setPresentation] = React.useState<Presentation>({} as Presentation);
    const { t, i18n } = useTranslation();
    const api = useApi();
    const theme = useTheme();
    const {user} = useAuth();

    const formSchema = {
        title: t("errors.field_required"),
        description: t("errors.field_required"),
        activity_id: t("errors.field_required")
    };

    const {
        register: addInput,
        formState: { errors },
        setValue: setInput,
        clearErrors: clearInputs,
        getValues: getInput,
        watch,
        reset,
        setError: setInputError,
        handleSubmit: handleStep,
    } = useForm<Record<any, any>>({reValidateMode: 'onBlur'});

    const resetModal = () => {
        clearInputs();
        reset();
        setDragEnter(false)
        setLoading(false)
        setAdded(false)
        setPreview('')
        setError('')
        setThumbs([])
        setThumbSelected(0)
        setPercent(0)
        setActiveStep(0)
        setVideo({})
        setPresentation({} as Presentation)
    }

    const onUploadProgress = (p: any) => {
        setPercent(p);
    }

    const postPresentation = () => {
        api.storePresentation(presentation).then((data) => {
            setAdded(true)
        }).catch((error) => {
            if (error.status == 422) {
                let resErrors: Array<any> = error.data?.errors
                setActiveStep(1);
                if (typeof resErrors == 'object') {
                    resErrors.map((err) => {
                        let field = err.field
                        setInputError(field, {
                            type: err.rule,
                            message: err.message
                        })
                    })
                } else {
                    setError(t('An error occured.'));
                }
            } else {
                setActiveStep(1);
                setError(t('An error occured.'));
            }
        }).finally(() => setLoading(false));
    }

    const uploadVideo = () => {
        console.log("up ff")
        if( presentation.video_url ) {
            postPresentation();
            return;
        }

        api.uploadVideoAwsChunked(video.file, onUploadProgress, (err:any) => {
            uploadVideo();
            setError(t('Error uploading the video, try again.'));
            setLoading(false);
        }, (data:any) => {
            if( !data.file ) {
                setError(t('Error uploading the video, try again.'));
                setLoading(false);
            } else {
                presentation.video_url = data.file;
                postPresentation();
            }
        })
    }
    const uploadPresentation = () => {
        console.log("up pp")
        setLoading(true)
        setError('');
        if( presentation.image_url ) {
            uploadVideo();
            return;
        }
        api.uploadToAws([b64toBlob(thumbs[thumbSelected])]).then((d) => {
            presentation.image_url = d.file;
            uploadVideo();
        }).catch((error) => {
            setActiveStep(1);
            setError(t('Error uploading thumbnail, try again.'));
            setLoading(false);
        })
    }

    const nextStep: SubmitHandler<Record<any, any>> = (values) => {
        setActiveStep(2)
        setPresentation({...values} as Presentation)
    }
    
    const steps = [
        t('Choose video'),
        t('Presentation information'),
        t('Upload video'),
    ];

    const setActiveThumb = (index: number) => {
        setThumbSelected(index);
    }

    const handleDrag = (files: File[]) => {
        setDragEnter(false)
        setLoading(true)
        setVideo({file: files[0]})
        importFileandPreview(files[0]).then((res) => {
            setPreview(res);
            setActiveStep(1);
            generateVideoThumbnails(files[0], 2, 'file').then((imgs) => {
                setThumbs(imgs);
            }).finally(() => setLoading(false))
        }).catch(() => {

        }).finally(() => setLoading(false))
    }

    const closeModal = () => {
        resetModal()
        onClose()
    }

    return (
        <BootstrapDialog fullScreen onClose={closeModal} aria-labelledby="customized-dialog-title" open={open}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={closeModal}>{t('Add new presentation')}</BootstrapDialogTitle>
            <DialogContent dividers>
                {activeStep == 0 && (
                    <Dropzone accept={{'video/*': ['.mp4', '.webm']}} disabled={loading} multiple={false} onDragEnter={() => setDragEnter(true)} onDragLeave={() => setDragEnter(false)} onDrop={handleDrag}>
                        {({getRootProps, getInputProps}) => (
                            <Paper variant="outlined" {...getRootProps()} sx={{display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', height: '100%'}}>
                                <input {...getInputProps()} />
                                <Box sx={{color: dragEnter ? 'primary.dark' : 'darkgrey', textAlign: 'center'}}>
                                    <Typography variant='h4'>{t('Drag & drop some files here, or click to select files')}</Typography>
                                    <CloudUploadOutlined sx={{fontSize: "3rem"}} />
                                    {loading && <CircularProgress />}
                                </Box>
                            </Paper>
                        )}
                  </Dropzone>
                )}
                {activeStep == 1 && (
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={8}>
                            <Box>
                                {!!error && <Alert sx={{marginBottom: "1rem"}} severity="error">{error}</Alert>}
                                <Typography variant='h5' color={'primary.dark'} sx={{marginBottom: '1.6rem'}}>{t('Details')}</Typography>
                                <TextField fullWidth error={!!errors.title} helperText={errors.title?.message?.toString()}  id="title" label={t('Presentation title')} variant="outlined" type="text" style={{marginBottom: "1.6rem"}} {...addInput('title', {required: formSchema.title})} />
                                <TextField fullWidth error={!!errors.description} helperText={errors.description?.message?.toString()}  id="description" label={t('Description')} variant="outlined" type="text" style={{marginBottom: "1.6rem"}} {...addInput('description', {required: formSchema.description})} multiline />
                                <ActivitySelect fullWidth id="activity_id" renderInput={(params) => <TextField error={!!errors.activity_id} helperText={errors.activity_id?.message?.toString()} {...params} label={t('Activity')} {...addInput('activity_id', {required: formSchema.activity_id})} onChange={() => {}} onBlur={() => {}} />} noOptionsText={t('No options')} loadingText={t('Loading')} style={{marginBottom: "1.6rem"}} onChange={(e, v) => setInput('activity_id', v)} value={watch('activity_id', '')}/>
                            </Box>
                            <Box>
                                <Typography variant='h5' color={'primary.dark'} sx={{marginBottom: '1.6rem'}}>{t('Thumbnails')}</Typography>
                                <Grid container spacing={2}>
                                    {thumbs.map((image: string, i:number) => (
                                        <Grid item xs={6} md={3} key={i}>
                                            <Box component={'div'} sx={{...theme.aspectratio.root, border: `1px solid ${thumbSelected == i ? theme.palette.primary.main: 'darkgrey'}`, opacity: thumbSelected == i ? '1' : '0.4', cursor: 'pointer'}} onClick={() => setActiveThumb(i)}>
                                                <Box component={'img'} src={image} sx={{...theme.aspectratio.child}}></Box>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <PresentationBox title={watch('title')} description={watch('description')} image_url={thumbs[thumbSelected]} preview user={user}/>
                        </Grid>
                    </Grid>
                )}
                {activeStep == 2 && (
                    <Card sx={{maxWidth: 460, marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary}}>
                        <Box sx={{overflow: "hidden", border: `1px solid ${theme.palette.primary.dark}`, borderRadius: theme.borders.primary}}>
                            <VideoPlayer options={{autoplay: true}} src={preview} />
                        </Box>
                        <Box sx={{marginTop: "1rem", marginBottom: "1rem"}}>
                            <Typography variant="h6" sx={{fontSize: "1rem", fontWeight: 'bold', color: 'black'}}>{presentation.title}</Typography>
                            {!!presentation.description && <Typography noWrap variant="body2" sx={{color: 'darkgrey'}}>{presentation.description}</Typography>}
                        </Box>
                        {!!added && <Alert sx={{marginBottom: "1rem"}} severity="success">{t("Your presentation has been uploaded")}</Alert>}
                        {!!error && <Alert sx={{marginBottom: "1rem"}} severity="error">{error}</Alert>}
                        {loading && !added && <LinearProgress value={percent} color='primary'/>}
                        {!loading && !added && <Button variant='outlined' fullWidth onClick={uploadPresentation}>{t('Upload')}</Button>}
                        {added && <Button variant='outlined' fullWidth onClick={closeModal}>{t('Finish')}</Button>}
                    </Card>
                )}
            </DialogContent>
            <DialogActions>
                <Box component={'div'} sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    {activeStep == 1 && <Button onClick={handleStep(nextStep)}>{t('Next')}</Button>}
                </Box>
            </DialogActions>
        </BootstrapDialog>
    );
}