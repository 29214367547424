import React, { useEffect, useState } from "react";
import { RequireAuth } from "../components/RequireAuth";
import { Avatar, Box, Card, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PaginationMeta, Presentation, User } from "../modules";
import { useApi } from "../providers/ApiProvider";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../providers/AuthProvider";
import PresentationBox from "../components/PresentationBox";
import UserBox from "../components/UserBox";
import DataEmptyBox from "../components/DataEmptyBox";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`author-tabpanel-${index}`}
            aria-labelledby={`author-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `author-tab-${index}`,
        'aria-controls': `author-tabpanel-${index}`,
    };
}

export const Author = () => {
    const { t, i18n } = useTranslation();
    const {user} = useAuth();
    const api = useApi();
    const theme = useTheme();
    const params = useParams();
    const [author, setAuthor] = useState<User>({} as User);
    const [loading, setLoading] = useState(false);
    const [following, setFollowing] = useState(false);
    const [presentations, setPresentations] = useState<Array<Presentation>>([]);
    const [pagination, setPagination] = useState<PaginationMeta>({current_page: 0} as PaginationMeta);
    const [followers, setFollowers] = useState<User[]>([]);
    const [subscriptions, setSubscriptions] = useState<User[]>([]);
    const [follpage, setFollpage] = useState<PaginationMeta>({current_page: 0} as PaginationMeta);
    const [subpage, setSubpage] = useState<PaginationMeta>({current_page: 0} as PaginationMeta);
    const [tab, setTab] = React.useState(0);

    const toggleTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const follow = () => {
        if( !following ) {
            setFollowing(true);
            api.followUser(author.slug).then((data) => {

                if( !author.cfollowers ) {
                    setAuthor({...author, ...{cfollowers: 0}})
                }

                if( data?.new ) {
                    setAuthor({...author, ...{cfollowers: (author.cfollowers || 0) + 1}})
                    author.followers?.push({
                        follower_id: user.id,
                        target_id: author.id
                    });
                } else {
                    setAuthor({...author, ...{cfollowers: (author.cfollowers || 0) - 1}})
                    const f = author.followers?.find(i => i.follower_id == user.id && i.target_id == author.id);
                    const i = author.followers?.indexOf(f);
                    if( i != undefined && i != -1 ) {
                        author.followers?.splice(i, 1);
                    }
                }
            }).catch(() => {

            }).finally(() => setFollowing(false));
        }
    }
    
    const getPresentations = (reset?: boolean) => {
        if( params.slug?.replace('@', '') ) {
            api.getUserPresentations(params.slug?.replace('@', ''), reset? 1 : pagination.current_page + 1, 12).then((data) => {
                if( data ) {
                    setPresentations(data.data);
                    setPagination(data.meta);
                }
            }).catch(() => {}).finally(() => {})

        }
    }

    const getFollowers = (reset?: boolean) => {
        if( params.slug?.replace('@', '') ) {
            api.getUserFollowers(params.slug?.replace('@', ''), reset? 1 : follpage.current_page + 1, 12).then((data) => {
                if( data ) {
                    setFollowers(data.data);
                    setFollpage(data.meta);
                }
            }).catch(() => {}).finally(() => {})
        }
    }

    const getSubscriptions = (reset?: boolean) => {
        if( params.slug?.replace('@', '') ) {
            api.getUserFollowings(params.slug?.replace('@', ''), reset? 1 : subpage.current_page + 1, 12).then((data) => {
                if( data ) {
                    setSubscriptions(data.data);
                    setSubpage(data.meta);
                }
            }).catch(() => {}).finally(() => {})
        }
    }

    useEffect(() => {
        if( tab == 0 ) {
            getPresentations(true);
        } else if( tab == 1 ) {
            getFollowers(true);
        } else {
            getSubscriptions(true);
        }
    }, [params.slug, tab]);

    useEffect(() => {
        if( params.slug?.replace('@', '') ) {
            setLoading(true)
            api.getPublicUser(params.slug?.replace('@', '')).then((data) => {
                setAuthor(data);
            }).catch(() => {}).finally(() => setLoading(false))
        }
        return () => {
            setTab(0);
        }
    }, [params.slug]);

    if( !loading && !author.id ) {
        return <DataEmptyBox label={t("This user not exists")} />;
    }

    return (
        <Box sx={{marginBottom: "2rem", maxWidth: "82rem", mx: "auto"}}>
            <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem"}}>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", mx: "-1rem", mt: "-1rem", p:"2rem", bgcolor: "primary.lighter"}}>
                    <Avatar src={author.avatar} alt={author.name} sx={{width: "6rem", height: "6rem", marginInlineEnd: "1.4rem"}}></Avatar>
                    <Box>
                        <Typography variant="h1" sx={{marginBottom: "0.1rem", fontWeight: "600", fontSize: "1.8rem", textTransform: "capitalize"}} color={'black'} children={author.name}/>
                        <Typography variant="h6" sx={{fontWeight: "400", fontSize: "1rem", textTransform: "capitalize", lineHeight: "1rem"}} color={'black'}>{author.cfollowers} {t("Followers")}</Typography>
                    </Box>
                    {user.id != author.id && <Box sx={{marginInlineStart: "auto"}}>
                        <LoadingButton loading={following} onClick={follow} sx={{minWidth: "6rem"}} variant="contained">{!!author.followers?.find((u) => u.follower_id == user.id)?.follower_id ? t("Unfollow") : t("Follow")}</LoadingButton>
                    </Box>}
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={toggleTab} aria-label="Author Tabs" scrollButtons="auto">
                            <Tab label={t("Presentations")} {...a11yProps(0)} />
                            <Tab label={t("Followers")} {...a11yProps(1)} />
                            <Tab label={t("Subscriptions")} {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tab} index={0}>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                        {presentations.map((presentation, index) => (
                            <Grid item xs={4} sm={4} md={3} lg={3} alignItems="stretch" key={index}>
                                <PresentationBox {...presentation}/>
                            </Grid>
                        ))}
                        </Grid>
                        {!presentations.length && <DataEmptyBox />}
                    </CustomTabPanel>
                    <CustomTabPanel value={tab} index={1}>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                        {followers.map((follower, index) => (
                            <Grid item xs={2} sm={4} md={3} lg={2} alignItems="stretch" key={index}>
                                <UserBox {...follower} />
                            </Grid>
                        ))}
                        </Grid>
                        {!followers.length && <DataEmptyBox />}
                    </CustomTabPanel>
                    <CustomTabPanel value={tab} index={2}>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                        {subscriptions.map((sub, index) => (
                            <Grid item xs={2} sm={4} md={3} lg={2} alignItems="stretch" key={index}>
                                <UserBox {...sub} />
                            </Grid>
                        ))}
                        </Grid>
                        {!subscriptions.length && <DataEmptyBox />}
                    </CustomTabPanel>
                </Box>
            </Card>
        </Box>
    );
}