import { LoadingButton } from "@mui/lab";
import { Box, Alert, TextField, Button } from "@mui/material";
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useApi } from "../providers/ApiProvider";
import { useAuth } from "../providers/AuthProvider";
import PasswordControl from "./PasswordControl";

export interface LoginModalContentProps {
    switchType: () => void;
    onSuccess: () => void;
    created?: boolean;
}

const LoginModalContent = ({onSuccess,switchType, created} : LoginModalContentProps) => {
    const { t } = useTranslation();
    const api = useApi();
    const {user} = useAuth();
    let [loading, setLoading] = React.useState(false)
    let [error, setError] = React.useState('')
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();
  
    const loginSchema = {
        email: t("errors.field_required"),
        password: t("errors.field_required"),
    };

    const {
        register: addInput,
        formState: { errors, isSubmitSuccessful },
        setValue: setInput,
        clearErrors: clearInputs,
        getValues: getInput,
        reset,
        setError: setInputError,
        handleSubmit: handleSignin,
    } = useForm<Record<any, any>>();

    const signin: SubmitHandler<Record<any, any>> = (values) => {
        setLoading(true)
        setError('')
        auth.signin(values.email, values.password).then((logged) => {
            if (logged) {
                onSuccess();
            } else {
                setError(t('Cannot connect, try again'))
            }
        }).catch((error) => {
            if (error.status == 422) {
                let resErrors: Array<any> = error.data?.errors
                if (typeof resErrors == 'object') {
                    resErrors.map((err) => {
                        let field = err.field
                        setInputError(field, {
                            type: err.rule,
                            message: err.message
                        })
                    })
                }
            } else if (error.status == 404) {
                setInputError('email', {
                    type: 'invalid',
                    message: t('errors.email_not_found')
                })
            }
        }).finally(() => setLoading(false));
    }
    return (
        <>
            <Box className="login-card-header">
                <h2>{t('Login')}</h2>
                <p>{t('Sign in to your account to use our service')}</p>
            </Box>
            <Box className="login-card-body">
                {!!error && <Alert severity="error" sx={{marginBottom: "2rem"}}>{error}</Alert>}
                {created && <Alert severity="success" sx={{marginBottom: "2rem"}}>{t('Your account has been created, sign in now')}</Alert>}
                <TextField fullWidth error={!!errors.email} helperText={errors.email?.message?.toString()}  id="email" label={t('Email')} variant="outlined" type="email" style={{marginBottom: "1.6rem"}} {...addInput('email', {required: loginSchema.email})} />
                <PasswordControl fullWidth error={!!errors.password} helperText={errors.password?.message?.toString()} {...addInput('password', {required: loginSchema.password})} onChange={(e) => setInput('password', e.target.value)} id="password" label={t('Password')} sx={{marginBottom: "1.6rem"}} ></PasswordControl>
                <LoadingButton fullWidth size="large" color="primary" onClick={handleSignin(signin)} loading={loading} variant="contained">{t('Sign in')}</LoadingButton>
                <hr style={{marginBlock: "2rem"}} />
                <Box sx={{textAlign: 'center'}}>
                <span style={{marginInlineEnd: "0.2rem"}}>{t("You don't have an account?")}</span>
                <Button variant={'outlined'} color={'primary'} onClick={switchType}>{t('Sign up')}</Button>
                </Box>
            </Box>
        </>
    );
}

export default LoginModalContent;