import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { Card, CardContent, TextField, Box, Link, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import "../css/login.css"
import { useState } from "react";
import AppLink from "../components/AppLink";
import PasswordControl from "../components/PasswordControl";
import { SubmitHandler, useForm } from "react-hook-form";

export const Login = () => {
  let [loading, setLoading] = useState(false)
  let [error, setError] = useState('')
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  let { t } = useTranslation();

  const formSchema = {
    email: t("errors.field_required"),
    password: t("errors.field_required"),
  };

  const {
    register: addInput,
    formState: { errors, isSubmitSuccessful },
    setValue: setInput,
    clearErrors: clearInputs,
    getValues: getInput,
    reset,
    setError: setInputError,
    handleSubmit: handleSignin,
  } = useForm<Record<any, any>>();
  
  let from = location.state?.from?.pathname || "/";
  let changed = location.state?.changed;
  let fromSearch = location.state?.from?.search || "";
  let created = location.state?.created || false

  const signin: SubmitHandler<Record<any, any>> = (values) => {
    setLoading(true)
    setError('')
    auth.signin(values.email, values.password).then((logged) => {
        console.log(from)
      if( logged ) {
        navigate({pathname: from, search: fromSearch}, {replace: true})
      } else {
        setError(t('Cannot connect, try again'))
      }
    }).catch((error) => {
      if( error.status == 422 ) {
        let resErrors: Array<any> = error.data?.errors
        if( typeof resErrors == 'object' ) {
          resErrors.map((err) => {
            let field = err.field
            setInputError(field, {
              type: err.rule,
              message: err.message
            })
          })
        }
      } else if( error.status == 404 ) {
        setInputError('email', {
          type: 'invalid',
          message: t('errors.email_not_found')
        })
      }
    }).finally(() => setLoading(false));
  }

  return (
    <div id="login">
      <Card sx={{ maxWidth: 500, borderRadius: "2rem", marginInline: "auto", padding: "2rem" }}>
        <CardContent>
          <div className="login-card-header">
            <h2>{t('Login')}</h2>
            <p>{t('Sign in to your account to use our service')}</p>
          </div>
          <div className="login-card-body">
            {!!error && <Alert severity="error" sx={{marginBottom: "2rem"}}>{error}</Alert>}
            {created && <Alert severity="success" sx={{marginBottom: "2rem"}}>{t('Your account has been created, sign in now')}</Alert>}
            {changed && <Alert severity="success" sx={{marginBottom: "2rem"}}>{t('Your password has been changed, sign in now')}</Alert>}
            <TextField fullWidth error={!!errors.email} helperText={errors.email?.message?.toString()}  id="email" label={t('Email')} variant="outlined" type="email" style={{marginBottom: "1.6rem"}} {...addInput('email', {required: formSchema.email})} />
            <PasswordControl fullWidth error={!!errors.password} helperText={errors.password?.message?.toString()} {...addInput('password', {required: formSchema.password})} onChange={(e) => setInput('password', e.target.value)} id="password" label={t('Password')} sx={{marginBottom: "0.6rem"}} ></PasswordControl>
            <Box sx={{textAlign: 'start',marginBottom: "1rem"}}>
              <AppLink to="/auth/resetpassword" color={'primary'} state={{from: location.state?.from}}>{t('Forgot password?')}</AppLink>
            </Box>
            <LoadingButton fullWidth size="large" color="primary" onClick={handleSignin(signin)} loading={loading} variant="contained">{t('Sign in')}</LoadingButton>
            <hr style={{marginBlock: "2rem"}} />
            <Box sx={{textAlign: 'center'}}>
              <span style={{marginInlineEnd: "0.2rem"}}>{t("You don't have an account?")}</span>
              <AppLink to="/auth/signup" color={'primary'} state={{from: location.state?.from}}>{t('Sign up')}</AppLink>
            </Box>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}