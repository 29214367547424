import { Card, Box, CardContent, Typography, Button, Avatar, useTheme } from "@mui/material";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import i18n from "../services/TransService";
import { useEffect, useState } from "react";
import { Palette, PaletteColor } from "@mui/material/styles";
import { Presentation } from "../modules";


interface PresentationItemProps {
    row?: boolean;
    preview?: boolean;
    image_url?: string;
    slug?: string;
    title?: string;
    description?: string;
    user?: Record<any, any>;
    activity?: Record<any, any>;
    created_at?: any;
}

const PresentationItem = ({row, image_url, slug, preview, title, activity, description, user, created_at}: PresentationItemProps) => {
    const theme = useTheme();
    const colors = {
        'primary': theme.palette.primary, 
        'secondary': theme.palette.secondary, 
        'greencolor': theme.palette.greencolor, 
        'bluecolor': theme.palette.bluecolor, 
        'redcolor': theme.palette.redcolor
    };

    const [color, setColor] = useState<PaletteColor>(theme.palette.primary);
    const [variant, setVariant] = useState<"primary" | "secondary" | "greencolor" | "bluecolor" | "redcolor" | "pinkone" | "purplecolor">('primary');

    useEffect(() => {
        const index = Math.floor(Math.random() * Object.keys(colors).length);
        setVariant(Object.keys(colors)[index] as "primary" | "secondary" | "greencolor" | "bluecolor" | "redcolor" | "pinkone" | "purplecolor");
        const chosen = Object.values(colors)[index];
        if( chosen ) setColor(chosen);
    }, []);

    return (
        <Box sx={{ borderRadius: theme.borders?.primary }}>
            <Box component={Link} to={`/presentation/${slug}`} sx={{ borderRadius: theme.borders?.primary }}>
                <Box component={'div'} sx={{ ...theme.aspectratio.root }}>
                    <Box component={'img'} src={image_url} sx={{ ...theme.aspectratio.child }}></Box>
                </Box>
            </Box>
            <Box sx={{marginTop: "1rem", display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h6" noWrap component={Link} to={`/presentation/${slug}`} sx={{ fontSize: "0.8rem", fontWeight: 'bold', color: 'black', textDecoration: 'none' }}>{title}</Typography>
                <Typography variant="body2" component={Link} to={`/activity/${activity?.slug}`} sx={{ fontSize: "0.7rem", fontWeight: 'bold', color: 'darkgrey', textDecoration: 'none' }}>{activity?.name}</Typography>
            </Box>
        </Box>
    )
}

export default PresentationItem;