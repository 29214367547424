import { useState, createContext, useContext } from 'react';
import { useDeviceSelectors } from 'react-device-detect';

type SidebarContext = {
    sidebarToggle: boolean;
    toggleSidebar: () => void;
    closeSidebar: () => void;
    mobileHandleSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContext>(
    {} as SidebarContext
);

const SBKEY = "sidebarToggle";

export const SidebarProvider = ({ children }: {children: React.ReactNode}) => {
    const [selectors, data] = useDeviceSelectors(window.navigator.userAgent)
    const { isMobile } = selectors

    const def = JSON.parse(localStorage.getItem(SBKEY) || 'true')
    const [sidebarToggle, setSidebarToggle] = useState(def);
    const toggleSidebar = () => {
        localStorage.setItem(SBKEY, JSON.stringify(!sidebarToggle));
        setSidebarToggle(!sidebarToggle);
    };

    const closeSidebar = () => {
        localStorage.setItem(SBKEY, JSON.stringify(false));
        setSidebarToggle(false);
    };

    const mobileHandleSidebar = () => {
        if( isMobile )
            closeSidebar()
    }

    window.addEventListener('resize', closeSidebar);

    return (
        <SidebarContext.Provider value={{ sidebarToggle, toggleSidebar, closeSidebar, mobileHandleSidebar }}
    > {children} </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    return useContext(SidebarContext);
}