import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeCreator } from './base';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

export interface ThemeProviderProps {
    children?: React.ReactNode;
}

export const ThemeContext = React.createContext(
    (themeName: string): void => {}
);

const ThemeProviderWrapper = ({ children }: { children: React.ReactNode }) => {
    const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
    const [themeName, _setThemeName] = useState(curThemeName);
    const theme = themeCreator(themeName);
    const setThemeName = (themeName: string): void => {
        localStorage.setItem('appTheme', themeName);
        _setThemeName(themeName);
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeContext.Provider value={setThemeName}>
                <CssBaseline />
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </ThemeContext.Provider>
        </StyledEngineProvider>
    );
};

export default ThemeProviderWrapper;