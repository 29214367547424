import { Card, Box, Avatar, Divider, useTheme, Typography, IconButton, InputAdornment, OutlinedInput, CircularProgress, FormControl, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../providers/AuthProvider";
import { useEffect, useState } from "react";
import { Comment, PaginationMeta } from "../modules";
import { useApi } from "../providers/ApiProvider";
import { SendRounded } from "@mui/icons-material";
import CommentItem from "./CommentItem";

export interface CommentSectionProps {
    presentation_id: number;
    user_id?: number;
}

const CommentSection = ({presentation_id, user_id} : CommentSectionProps) => {
    const [comments, setComments] = useState<Array<Comment>>([]);
    const [comment, setComment] = useState('');
    const [pagination, setPagination] = useState<PaginationMeta>({current_page: 0} as PaginationMeta);
    const [loading, setLoading] = useState<boolean>(false);
    const [commenting, setCommenting] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    const [reached, setReached] = useState<boolean>(false);
    const [sortby, setSortBy] = useState<'asc' | 'desc' | 'replies'>('desc');
    const theme = useTheme();
    const api = useApi();
    const { t, i18n } = useTranslation();
    const {actionAuth, user, isConnected, opened} = useAuth();

    const getComments = (sortt: any = 'desc', reset: boolean = false) => {
        if( loading || finished )
            return;
        setLoading(true);
        api.getPresentationComments(presentation_id, reset ? 1 : pagination.current_page + 1, 5, sortt).then((data: {data: Array<Comment>, meta: PaginationMeta}) => {
            if( data ) {
                setComments(reset ? data.data : comments.concat(data.data));
                setPagination(data.meta);
            }
        }).finally(() => setLoading(false))
    }

    const sendComment = () => {
        if( comment && !commenting ) {
            setCommenting(true);
            api.commentPresentation(presentation_id, comment).then((data: Comment) => {
                setComment('');
                comments.unshift(data);
            }).finally(() => setCommenting(false))
        }
    }

    const isBottom = (el:HTMLElement) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('comments-section');
        if (wrappedElement && isBottom(wrappedElement) && !loading && !finished) {
            setReached(true)
        } else {
            setReached(false)
        }
    };

    const sortComments = (sort:any) => {
        setComments([]);
        setFinished(false);
        setPagination({current_page: 0} as PaginationMeta);
        getComments(sort, true);
        setSortBy(sort);
    }

    useEffect(() => {
        if( pagination.current_page == pagination.last_page ) {
            setFinished(true)
        }
    }, [pagination])

    useEffect(() => {
        if( reached && !loading && !finished) {
            getComments(sortby);
        }
    }, [reached])

    useEffect(() => {
        document.addEventListener('scroll', trackScrolling);
        return () => {
            document.removeEventListener('scroll', trackScrolling);
        }
    }, []);

    useEffect(() => {
        getComments();
        return () => {
            setPagination({current_page: 0} as PaginationMeta);
            setComments([]);
            setFinished(false);
        }
    }, [presentation_id]);

    return(
        <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem"}}>
            <Box sx={{display: "flex", flexDirection: "row", width: "100%"}}>
                <Avatar alt={user.name} src={user.avatar} sx={{width: "3rem", height: "3rem", flex: "0 1 auto"}}/>
                <Box sx={{paddingInlineStart: "1rem", flex: 1}}>
                <OutlinedInput
                    fullWidth
                    placeholder={t("Add feedback")}
                    multiline
                    type={'text'}
                    onClick={() => {
                        if(!isConnected() && !opened) {
                            actionAuth(() => {});
                        }
                    }}
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                    endAdornment={
                        user.id ? <InputAdornment position="end">
                            <IconButton onClick={sendComment}
                                edge="end"
                            >
                                {commenting ? <CircularProgress size={'1.6rem'} /> : <SendRounded />}
                            </IconButton>
                        </InputAdornment> : ''
                    }/>
                </Box>
            </Box>
            <Divider sx={{my: "1rem"}}/>
            <Box id={'comments-section'} sx={{marginBottom: "1rem"}}>
                <Box sx={{display: "flex", flexDirection: "row", marginBottom: "1rem"}}>
                    <Typography sx={{fontWeight: 800, fontSize: "0.8rem"}}>{pagination.total} {t("feedbacks")}</Typography>
                    <FormControl sx={{ marginInlineStart: "auto", minWidth: 120, padding: 0 }} size="small">
                        <Select
                            variant="standard"
                            sx={{padding: 0, fontWeight: 800, fontSize: "0.8rem"}}
                            value={sortby}
                            onChange={(s) => sortComments(s.target.value as any)}
                            displayEmpty
                            size="small"
                        >
                            <MenuItem value={'desc'}>{('Newest feedbacks')}</MenuItem>
                            <MenuItem value={'asc'}>{('Oldest feedbacks')}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{marginBottom: "1rem"}}>
                    {!!comments.length && comments.map((c, i) => (
                        <CommentItem key={`comment-item-${c.id}-${i + 1}`} presentation_id={presentation_id} user_id={user_id} comment={c} /> 
                    ))}
                </Box>
                {loading && <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    <CircularProgress color="primary" size={"1.6rem"} />
                </Box>}
            </Box>
        </Card>
    )
}

export default CommentSection;