import { styled, useTheme } from '@mui/material/styles';
import { drawerWidth } from "../../store/constant";
import { useSidebar } from '../../providers/SidebarProvider';
import React from 'react';

interface MainProp {
    open: boolean;
}
  
const MainWrapper = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<MainProp>(({ theme, open }) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create(
        'margin',
        open
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
            }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
            }
    ),
    [theme.breakpoints.up('md')]: {
        marginLeft: open ? 0 : -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px',
        marginRight: '10px'
    }
}));

export const Main = ({children}: {children: React.ReactNode}) => {

    const theme = useTheme();
    const {sidebarToggle, toggleSidebar} = useSidebar();

    return (
        <>
            <MainWrapper theme={theme} open={sidebarToggle}>{children}</MainWrapper>
        </>
    );
  }