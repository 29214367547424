import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationFR from '../langs/fr/translation.json'
import translationAR from '../langs/ar/translation.json'

export const resources = {
  fr: {
    translation: translationFR,
  },
  ar: {
    translation: translationAR,
  },
}

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
        escapeValue: false,
    },
})
export default i18n;