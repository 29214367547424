import axios, { AxiosInstance } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { chunk } from "../services/VideoService";
import { User } from "../modules";
import { ClientHTTP, decryptData, makeSek } from "../helpers";

const API_URL = 'https://v2.monkidly.com'
const AWS_URL = 'https://myfiles.oijeorg.com/api';

interface ApiContextType {
    getAws: () => AxiosInstance;
    auth: (user: any, token: string) => void;
    requestcode: (email: string, code: any) => Promise<any>;
    changePassword: (data: any) => Promise<any>;
    login: (email: string, password: string) => Promise<any>;
    register: (data: Record<string, any>) => Promise<any>;
    getActivitiesCollection: (all?:boolean) => Promise<any>;
    uploadToFile: (data:any) => Promise<any>;
    uploadToAws: (data:any) => Promise<any>;
    uploadVideoAwsChunked: (file: any, onUploadProgress: any, onError: any, onSuccess:any) => any;
    storePresentation: (data:any) => Promise<any>;
    getLibraryPresentations : (page?: number) => Promise<any>;
    getDashboardPresentations : (page?: number) => Promise<any>;
    getPublicPresentations : (page?: number, limit?: number) => Promise<any>;
    getActivityPresentations: (id: any, page?: number) => Promise<any>;
    getPresentation: (id: any) => Promise<any>;
    votePresentation: (id: number) => Promise<any>;
    logPresentation: (id: number, type:string, meta?:any) => Promise<any>;
    savePresentation: (id: number) => Promise<any>;
    commentPresentation: (id: number, data: string, reply_to?: number) => Promise<any>;
    editCommentPresentation: (id: number, data: string) => Promise<any>;
    deleteCommentPresentation: (id: number) => Promise<any>;
    getPresentationComments: (id: number, page?: number, limit?: number, type?: string) => Promise<any>;
    getAvailableProfiles: (page: number, limit: number, type?: string) => Promise<any>;
    getProfile: () => Promise<any>;
    getPublicUser: (slug: string) => Promise<any>;
    followUser: (slug: string) => Promise<any>;
    getFollowings: (page?: number, limit?: number) => Promise<any>;
    getUserFollowings: (slug: string, page?: number, limit?: number) => Promise<any>;
    getUserFollowers: (slug: string, page?: number, limit?: number) => Promise<any>;
    getUserPresentations: (slug: string, page?: number, limit?: number) => Promise<any>;
}

let ApiContext = React.createContext<ApiContextType>(null!);

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
    let [_auth, setAuth] = useState<{user: User, token: string}>({} as {user: User, token: string})

    let auth = (u:any, tok: string) => {
        _auth.user = u;
        _auth.token = tok;
    }

    const {i18n} = useTranslation();
    const http = axios.create({
        baseURL: API_URL,
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin' : '*',
            'X-SERVICE-APP': 'proffer'
        }
    });

    if( http ) {
        http.defaults.params = {};
        let key = makeSek();
        
        http.interceptors.request.use(function (config) {
            config.params['key'] = key;
            if( config?.headers && (config?.headers['Content-Type'] == 'application/json' || config?.headers['content-type'] == 'application/json') ) {
                if( config.data ) {
                    let client = new ClientHTTP(config.data)
                    config.data = client;
                }
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });
        http.interceptors.response.use(function (response) {
            let data = decryptData(response.data, key)
            response.data = data
            return response;
        }, function (error) {
            if( error && error.response && error.response.data ) {
                error.response.data = decryptData(error.response.data, key)
            }
            return Promise.reject(error);
        });
    }

    const aws:AxiosInstance = axios.create({
        baseURL: AWS_URL,
        timeout: 0,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin' : '*'
        }
    });

    let initCookiesService = () => {
        if( http ) {
            try {
                http.defaults.headers.common['Authorization'] = `Bearer ${_auth.token}`;
                http.defaults.headers['Authorization'] = `Bearer ${_auth.token}`;
            } catch (error) {}
        }
    }

    let initLocaleService = () => {
        if( http ) {
            try {
                http.defaults.headers.common['Accept-language'] = i18n.language;
                http.defaults.headers['Accept-language'] =  i18n.language;
            } catch (error) {}
        }
    }

    let initCrypting = () => {
    }

    let send = (callback: Function): Promise<any> => {
        initCrypting();
        initCookiesService();
        initLocaleService();
        return callback()?.catch((error:any) => {
            throw error.response
        });
    }

    let requestcode = (email: string, code: any) => {
        return send(() => {
            return http.post('/account/reset', {email, code}).then((response) => response.data);
        })
    }

    let changePassword = (data:any) => {
        return send(() => {
            return http.post('/account/v3/p', data).then((response) => response.data);
        })
    }

    let login = (email: string, password: string) => {
        return send(() => {
            return http.post('/auth/login', {email: email, password: password}).then((response) => response.data);
        })
    }

    let register = (data: Record<string, any>) => {
        return send(() => {
            return http.post('/account/signup', data).then((response) => response.data);
        })
    }

    let getActivitiesCollection = (all:boolean=false) => {
        return send(() => {
            return http.get(`/v1/activities?all=${all}`).then((response) => response.data)
        })
    }

    let uploadToFile = (data: any) => {
        return send(() => {
            const formData = new FormData();
        
            formData.append(
                "file",
                data[0],
                data[0]?.name
            );

            return http.post('/dashboard/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => response.data).then((data) => {
                data.file = `${API_URL}/${data.file}`
                return data;
            });
        })
    }

    let uploadToAws = (data: any) => {
        return send(() => {
            const formData = new FormData();
        
            formData.append(
                "image",
                data[0],
                data[0]?.name
            );

            return aws.post('/image-upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => response.data).then((data) => {
                return data;
            });
        })
    }

    let storePresentation = (data: any) => {
        return send(() => {
            return http.post('/presentations/create', data).then(response => response.data);
        });
    }

    let uploadVideoAwsChunked = (file: any, onUploadProgress: any, onError: any, onSuccess:any) => {
        return chunk(aws, '/chunk-upload', file, onUploadProgress, onError, onSuccess);
    }

    let getLibraryPresentations = (page: number = 1) => {
        return send(() => {
            return http.get(`/presentations/library/?page=${page}`).then((response) => response.data)
        })
    }

    let getDashboardPresentations = (page: number = 1) => {
        return send(() => {
            return http.get(`/presentations/list/?page=${page}`).then((response) => response.data)
        })
    }
    
    let getPublicPresentations = (page: number = 1, limit: number = 0) => {
        return send(() => {
            return http.get(`/v1/presentations/list/?page=${page}${limit?`&limit=${limit}`:''}`).then((response) => response.data)
        })
    }

    let getActivityPresentations = (id: any, page: number = 1) => {
        return send(() => {
            return http.get(`/v1/presentations/${id}?page=${page}&slug=${typeof id == 'string' ? '1' : '0'}`).then((response) => response.data)
        })
    }

    let getPresentation = (id: any) => {
        return send(() => {
            if( typeof id == 'number' ) {
                return http.get(`/v1/presentations/get/${id}`).then((response) => response.data)
            } else {
                return http.get(`/v1/presentations/slug/${id}`).then((response) => response.data)
            }
        })
    }

    let votePresentation = (id: number) => {
        return send(() => {
            return http.post(`/presentations/vote/${id}`, {}).then(response => response.data);
        })
    }

    let logPresentation = (id: number, type:string, meta?:any) => {
        return send(() => {
            return http.post(`/presentations/log/${id}`, {type, meta}).then(response => response.data);
        })
    }

    let savePresentation = (id: number) => {
        return send(() => {
            return http.post(`/presentations/save/${id}`, {}).then(response => response.data);
        })
    }

    let commentPresentation = (id: number, data: string, reply_to?: number) => {
        return send(() => {
            return http.post(`/presentations/comment/`, {content: data, id, reply_id: reply_to}).then(response => response.data);
        })
    }

    let editCommentPresentation = (id: number, data: string) => {
        return send(() => {
            return http.post(`/presentations/comment/edit`, {content: data, id}).then(response => response.data);
        })
    }

    let deleteCommentPresentation = (id: number) => {
        return send(() => {
            return http.post(`/presentations/comment/delete`, {id}).then(response => response.data);
        })
    }

    let getPresentationComments = (id: number, page: number = 1, limit: number = 0, type?: string) => {
        return send(() => {
            return http.post(`/v1/presentations/comments`, {id, page, limit, type}).then((response) => response.data)
        })
    }

    let getAvailableProfiles = (page: number = 1, limit: number = 0, type?: string) => {
        return send(() => {
            return http.post(`/v1/profiles/type`, {page, limit, type}).then((response) => response.data)
        })
    }

    let getProfile = () => {
        return send(() => {
            return http.get('/profiles/show').then((response) => response.data)
        })
    }
    
    let getPublicUser = (slug: string) => {
        return send(() => {
            return http.get(`/profile/u/${slug}`).then((response) => response.data)
        })
    }

    let followUser = (slug: string) => {
        return send(() => {
            return http.post(`/v2/follow/${slug}`, {}).then(response => response.data);
        })
    }

    let getFollowings = (page: number = 1, limit: number = 0) => {
        return send(() => {
            return http.get(`/v2/followings/?page=${page}${limit != 0 ? `&limit=${limit}` : ''}`).then((response) => response.data)
        })
    }

    let getUserFollowings = (slug: string, page: number = 1, limit: number = 0) => {
        return send(() => {
            return http.get(`/profile/u/out/${slug}/?page=${page}${limit != 0 ? `&limit=${limit}` : ''}`).then((response) => response.data)
        })
    }

    let getUserFollowers = (slug: string, page: number = 1, limit: number = 0) => {
        return send(() => {
            return http.get(`/profile/u/in/${slug}/?page=${page}${limit != 0 ? `&limit=${limit}` : ''}`).then((response) => response.data)
        })
    }

    let getUserPresentations = (slug: string, page: number = 1, limit: number = 0) => {
        return send(() => {
            return http.get(`/v1/presentations/user/${slug}/?page=${page}${limit?`&limit=${limit}`:''}`).then((response) => response.data)
        })
    }

    let getAws = () => {
        return aws;
    }

    useEffect(() => {
        
    }, []);

    let value = { auth, login, register, getActivitiesCollection, uploadToFile, uploadVideoAwsChunked, storePresentation, getLibraryPresentations, getDashboardPresentations, getPublicPresentations, getActivityPresentations, uploadToAws, getPresentation, votePresentation, logPresentation, savePresentation, commentPresentation, getPresentationComments, editCommentPresentation, deleteCommentPresentation, getAvailableProfiles, getPublicUser, getProfile, followUser, getFollowings, getUserFollowings, getUserFollowers, getUserPresentations, getAws, requestcode, changePassword };

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
  
export const useApi = () => {
    return React.useContext(ApiContext);
}