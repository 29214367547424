import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { Card, CardContent, TextField, Box, Link, Stepper, Step, StepLabel, RadioGroup, 
  Button, Autocomplete } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import "../css/login.css"
import { Fragment, useState } from "react";
import PersonIcon from '@mui/icons-material/Person';
import BoxRadio from "../components/BoxRadio";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import AppLink from "../components/AppLink";
import PasswordControl from "../components/PasswordControl";

export const Signup = () => {
  let [loading, setLoading] = useState(false);
  let [activeStep, setActiveStep] = useState(0);
  //Form Data
  let [role, setRole] = useState('etudiant');
  // End Form Data
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  let { t } = useTranslation();

  const genders = ['female', 'male']
  const levels = ['TPS','PS','MS','GS','CP','CE1','CE2','CM1','CM2','CE6','CS7','CS8','CS9','TC','1BAC','2BAC']
  const steps = [t('Select account type'), t('Personal information'), t('Contact information')];
  const types = [{
    label: t('Student'),
    desc: t('For students and kids'),
    value: "etudiant"
  }, {
    label: t('School'),
    desc: t('School can create their content also'),
    value: "school"
  }];
  
  const formSchema = {
    name: t("errors.invalid_name"),
    gender: t("errors.field_required"),
    city: t("errors.field_required"),
    school_address: t("errors.field_required"),
    director_name: t("errors.field_required"),
    study_level: t("errors.field_required"),
    school: t("errors.field_required"),
    email: t("errors.field_required"),
    phone: t("errors.field_required"),
    password: t("errors.field_required"),
  };

  const {
    register: personal,
    formState: { errors, isSubmitSuccessful },
    setValue: setPersonal,
    clearErrors: clearPersonals,
    getValues: getPersonalInfo,
    reset,
    setError: setPersonalError,
    handleSubmit: handleNext,
  } = useForm<Record<any, any>>();

  const {
    register: contact,
    formState: { errors: cerrors },
    setValue: setContact,
    clearErrors: clearContacts,
    reset: contactReset,
    setError: setContactError,
    handleSubmit,
    control: contactCtrl
  } = useForm<Record<any, any>>();

  const onLastStep: SubmitHandler<Record<any, any>> = (values) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  let from = location.state?.from?.pathname || "/";
  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
  const backStep = () => {
    setLoading(false)
    clearPersonals()
    clearContacts()
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const signup: SubmitHandler<Record<any, any>> = (values) => {
    let personal_data = getPersonalInfo();
    if( personal_data.name )
      personal_data.name = personal_data.name.toString().toLowerCase();
    let data = {...values, ...personal_data, role, speciality: 'none'}
    setLoading(true)
    auth.signup(data).then((res) => {
      navigate('/auth/login', {state: {
        from: location.state?.from,
        created : true
      }})
    }).catch((error) => {
      if( error.status == 422 ) {
        let resErrors: Array<any> = error.data?.errors
        if( typeof resErrors == 'object' ) {
          resErrors.map((err) => {
            let field = err.field
            if( ['email', 'phone', 'password'].includes(field) ) {
              setContactError(field, {
                type: err.rule,
                message: err.message
              })
            } else {
              backStep()
              setPersonalError(field, {
                type: err.rule,
                message: err.message
              })
            }
          })
        }
      }
    }).finally(() => setLoading(false));
  }

  return (
    <div id="signup">
      <Card sx={{ maxWidth: 500, borderRadius: "2rem", marginInline: "auto", padding: "2rem" }}>
        <CardContent>
          <div className="login-card-header">
            <h2>{t('Sign up')}</h2>
            <p>{t('Sign up now and feel free to be a creator')}</p>
          </div>
          <Stepper sx={{marginBottom: '2rem'}} activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep == 0 && (<Fragment>
            <RadioGroup
              aria-label="Type Account"
              value={role}
              name="type_account"
              sx={{
                flexDirection: 'row',
                marginBottom: '2rem',
                gap: 2
              }}
            >
              {types.map((type, key) => (
                <BoxRadio key={key} onChange={(event) => setRole(event.target.value)} icon={<PersonIcon/>} value={type.value} label={type.label} desc={type.desc} />
              ))}
            </RadioGroup>
            <LoadingButton fullWidth size="large" color="primary" onClick={nextStep} variant="contained">{t('Get started')}</LoadingButton>
          </Fragment>)}
          {activeStep == 1 && (<Fragment>
            <Box component={'form'} noValidate className="login-card-body" onSubmit={handleNext(onLastStep)}>
              <TextField error={!!errors.name} helperText={errors.name?.message?.toString()} fullWidth id="name" label={t(role == 'etudiant' ? 'Fullname' : 'School name')} variant="outlined" type="text" style={{marginBottom: "1.6rem"}} {...personal('name', {required: formSchema.name})}/>
              {role == 'school' && <TextField error={!!errors.director_name} helperText={errors.director_name?.message?.toString()} fullWidth id="director_name" label={t('Director name')} variant="outlined" type="text" style={{marginBottom: "1.6rem"}} {...personal('director_name', {required: formSchema.director_name})}/>}

              <Autocomplete fullWidth getOptionLabel={(option) => t(`genders.${option}`)} onChange={(e, v) => setPersonal('gender', v)} value={getPersonalInfo('gender')} id="gender" options={genders} renderInput={(params) => <TextField error={!!errors.gender} helperText={errors.gender?.message?.toString()} {...personal('gender', {required: formSchema.gender})} {...params} onChange={() => {}} onBlur={() => {}} label={t('Gender')} />} noOptionsText={t('No options')} loadingText={t('Loading')} style={{marginBottom: "1.6rem"}} />

              {role == 'etudiant' && <TextField error={!!errors.school} helperText={errors.school?.message?.toString()} fullWidth id="school" label={t('School name')} variant="outlined" type="text" style={{marginBottom: "1.6rem"}} {...personal('school', {required: formSchema.school})}/>}

              <TextField error={!!errors.city} helperText={errors.city?.message?.toString()} fullWidth id="city" label={t('City')} variant="outlined" type="text" style={{marginBottom: "1.6rem"}} {...personal('city', {required: formSchema.city})}/>

              {role == 'etudiant' && <Autocomplete fullWidth id="study_level" options={levels} renderInput={(params) => <TextField error={!!errors.study_level} helperText={errors.study_level?.message?.toString()} {...params} label={t('Study level')} {...personal('study_level', {required: formSchema.study_level})} onChange={() => {}} onBlur={() => {}} />} noOptionsText={t('No options')} loadingText={t('Loading')} style={{marginBottom: "1.6rem"}} onChange={(e, v) => setPersonal('study_level', v)}  value={getPersonalInfo('study_level')}/>}

              {role == 'school' && <TextField error={!!errors.school_address} helperText={errors.school_address?.message?.toString()} fullWidth id="school_address" label={t('School address')} variant="outlined" multiline style={{marginBottom: "1.6rem"}} {...personal('school_address', {required: formSchema.school_address})}/>}

              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button variant="outlined" color="inherit" onClick={backStep} sx={{ mr: 1 }}>{t('Back')}</Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant="contained" color="primary" type="submit" sx={{ mr: 1 }}>{t('Next')}</Button>
              </Box>
              <hr style={{marginBlock: "2rem"}} />
              <Box sx={{textAlign: 'center'}}>
                <span style={{marginInlineEnd: "0.2rem"}}>{t("You already have an account?")}</span>
                <Link href="/" color={'primary'}>{t('Sign in')}</Link>
              </Box>
            </Box>
          </Fragment>)}
          {activeStep == steps.length - 1 && (<Fragment>
            <div className="login-card-body">
              <TextField fullWidth error={!!cerrors.email} helperText={cerrors.email?.message?.toString()}  {...contact('email', {required: formSchema.email})} id="email" label={t('Email')} variant="outlined" type="text" style={{marginBottom: "1.6rem"}} />
              <Controller
                name="phone"
                control={contactCtrl}
                rules={{ validate: matchIsValidTel }}
                render={({ field, fieldState }) => (
                  <MuiTelInput {...field} fullWidth id="phone" error={fieldState.invalid} helperText={cerrors.phone?.message?.toString()} {...contact('phone', {required: formSchema.phone})} onChange={(v) => setContact('phone', v)} label={t('Phone number')} variant="outlined" style={{marginBottom: "1.6rem"}} excludedCountries={['IL', 'EH']} preferredCountries={['MA']} defaultCountry="MA" />
                )}
              />

              <PasswordControl fullWidth error={!!cerrors.password} helperText={cerrors.password?.message?.toString()} {...contact('password', {required: formSchema.password})} onChange={(e) => setContact('password', e.target.value)} id="password" label={t('Password')} sx={{marginBottom: "1.6rem"}} ></PasswordControl>
              
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button color="inherit" onClick={backStep} sx={{ mr: 1 }} disabled={loading}>{t('Back')}</Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <LoadingButton fullWidth size="large" color="primary" onClick={handleSubmit(signup)} loading={loading} variant="contained">{t('Sign up')}</LoadingButton>
              </Box>
              <hr style={{marginBlock: "2rem"}} />
              <Box sx={{textAlign: 'center'}}>
                <span style={{marginInlineEnd: "0.2rem"}}>{t("You already have an account?")}</span>
                <AppLink to="/auth/login" color={'primary'} state={{from: location.state?.from}}>{t('Sign in')}</AppLink>
              </Box>
            </div>
          </Fragment>)}
        </CardContent>
      </Card>
    </div>
  );
}