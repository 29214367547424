import React, { useEffect } from "react";
import videojs from 'video.js'

// Styles
import "video.js/dist/video-js.css";

interface IVideoPlayerProps {
    pId?: any;
    options: Record<any, any>;
    src?: string;
    onReady?: (thisPlayer: any) => void;
     
}

const initialOptions: Record<any, any> = {
    controls: true,
    fluid: true,
    controlBar: {
        volumePanel: {
            inline: false
        }
    }
};

const VideoPlayer: React.FC<IVideoPlayerProps> = ({ src, options, onReady, pId }) => {
    const videoNode = React.useRef<HTMLVideoElement>(null);
    const player = React.useRef<Record<any, any> | null>(null);

    useEffect(() => {
        if (!player.current) {
            const videoElement = videoNode.current;
            if (!videoElement) return;
            const p = player.current =
                videojs(videoElement, {
                    ...initialOptions,
                    ...options
                }, () => {
                    onReady && onReady(p);
                });
        }
    }, [options, videoNode]);

    // Destroy video.js player on component unmount
    useEffect(() => {
        console.log('js2')
        const p = player.current;
        return () => {
            if (p) {
                //p.dispose();
                //player.current = null;
            }
        };
    }, [player]);

    return <video ref={videoNode} src={src} className="video-js" />;
};

export default VideoPlayer;