import { Card, Box, Avatar, TextField, Divider, useTheme, Typography, IconButton, InputAdornment, OutlinedInput, CircularProgress, Paper, Link as MuiLink, FormControl, Select, MenuItem, Button, Menu, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../providers/AuthProvider";
import { useEffect, useRef, useState } from "react";
import { Comment, PaginationMeta } from "../modules";
import { useApi } from "../providers/ApiProvider";
import { VisibilityOff, Visibility, SendRounded, SubdirectoryArrowRightOutlined, TurnLeftOutlined, MoreHorizOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { parseStringHtml } from "../helpers";
import Moment from "react-moment";


export interface CommentItemProps {
    presentation_id: number;
    user_id?: number;
    comment: Comment;
}

const CommentItem = ({presentation_id, user_id, comment} : CommentItemProps) => {
    const thisComment = useRef<HTMLElement>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const {actionAuth, user, isConnected, opened} = useAuth();
    const [show, setShow] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [del, setDel] = useState<boolean>(false);
    const [commenting, setCommenting] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const api = useApi();
    const [replyText, setReplyText] = useState('');
    const [commentText, setCommentText] = useState('');
    const [reply, setReply] = useState<number>(0);

    const handleDelEl = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseEl = () => {
        setAnchorEl(null);
    };

    const toDeleteComment = () => {
        setDel(true);
        handleCloseEl();
    }

    const toEdittComment = () => {
        setCommentText(comment.content);
        setEdit(true);
        handleCloseEl();
    }

    const deleteComment = () => {
        setDel(false);
        if( !commenting ) {
            setCommenting(true);
            api.deleteCommentPresentation(comment.id).then((data: any) => {
                if( thisComment.current ) {
                    thisComment.current.remove();
                }
            }).catch(() => {}).finally(() => setCommenting(false))
        }
    }

    const editComment = () => {
        if( commentText && !commenting ) {
            setCommenting(true);
            api.editCommentPresentation(comment.id, commentText).then((data: Comment) => {
                setCommentText('');
                setEdit(false);
                comment.content = data.content;
            }).catch(() => {}).finally(() => setCommenting(false))
        }
    }

    const replyComment = () => {
        if( replyText && !commenting ) {
            setCommenting(true);
            api.commentPresentation(presentation_id, replyText, reply).then((data: Comment) => {
                setReply(0);
                setReplyText('');
                comment.replies?.unshift(data);
            }).catch(() => {}).finally(() => setCommenting(false))
        }
    }

    return (
        <Box ref={thisComment} sx={{display: "flex", flexDirection: "row", marginBottom: "2rem"}}>
            <Box component={Link} to={`/u/@${comment.user?.slug}`}>
                <Avatar alt={comment.user?.name} src={comment.user?.avatar} sx={{width: "3rem", height: "3rem"}} />
            </Box>
            <Box sx={{flex: "0 1 auto", width: "100%", paddingInlineStart: "1rem"}}>
                <Box sx={{marginBottom: "0.6rem", display: "flex", flexDirection: "row"}}>
                    <MuiLink sx={{color: "primary", fontWeight: "400", textTransform: "capitalize", fontSize: "0.8rem", textDecoration: "none", marginInlineEnd: "0.6rem"}} component={Link} to={`/u/@${comment.user?.slug}`}>
                        {user_id == comment.user?.id ? 
                        <Box component={'span'} sx={{px: "0.4rem", py: "0.2rem", bgcolor: 'primary.main', color: "white", borderRadius: "0.4rem"}}>{comment.user?.name}</Box> : 
                        <Box component={'span'} sx={{px: "0.4rem", py: "0.2rem"}}>{comment.user?.name}</Box>}
                    </MuiLink>
                    <Moment style={{fontSize: "0.8rem"}} fromNow locale={i18n.language}>{comment.created_at}</Moment>
                    {comment.user_id == user.id && <Box sx={{marginInlineStart: "auto"}}>
                        <MoreHorizOutlined
                            fontSize="small"
                            onClick={handleDelEl}
                            sx={{
                                color: 'primary.main',
                                cursor: 'pointer'
                            }}
                            aria-controls="menu-popular-card"
                            aria-haspopup="true"
                        />
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseEl}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={toDeleteComment}>{t("Delete")}</MenuItem>
                            <MenuItem onClick={toEdittComment}>{t("Edit")}</MenuItem>
                        </Menu>
                    </Box>}
                </Box>
                <Paper sx={{bgcolor: 'appgrey.main', padding: "1rem", borderRadius: theme.borders.primary}} elevation={0}>
                    {!edit && <Box component={'div'}>{parseStringHtml(comment.content)}</Box>}
                    {edit && <>
                        <OutlinedInput
                            fullWidth
                            placeholder={t("Edit feedback")}
                            multiline
                            type={'text'}
                            onClick={() => {
                                if(!isConnected() && !opened) {
                                    actionAuth(() => {});
                                }
                            }}
                            onChange={(e) => setCommentText(e.target.value)}
                            value={commentText}
                            endAdornment={
                                user.id ? <InputAdornment position="end">
                                    <IconButton onClick={editComment}
                                        edge="end"
                                    >
                                        {commenting ? <CircularProgress size={'1.6rem'} /> : <SendRounded />}
                                    </IconButton>
                                </InputAdornment> : ''
                            }/>
                        <MuiLink component={'button'} color={'appgrey.darker'} underline="none" sx={{fontSize: "0.8rem"}} onClick={() => setEdit(false)}>{t("Cancel")}</MuiLink>
                    </>}
                </Paper>
                <Box sx={{textAlign: "start", paddingTop: "0.6rem", display: "flex", flexDirection: "row", marginBottom: "0.6rem"}}>
                    {!!comment.replies?.length && !comment.reply_id && <><MuiLink component={'button'} underline="none" sx={{fontSize: "0.8rem"}} onClick={() => setShow(!show)}>
                        {show ? <><TurnLeftOutlined sx={{fontSize: "0.8rem"}} /> {t("Hide replies")}</>: <><SubdirectoryArrowRightOutlined sx={{fontSize: "0.8rem"}} /> {t("View replies")}</>} ({comment.replies.length})
                        </MuiLink><Box sx={{mx: "0.4rem"}}></Box></>}
                    {!comment.reply_id && <MuiLink component={'button'} underline="none" sx={{fontSize: "0.8rem"}} onClick={() => setReply(comment.id)}>{t("Reply")}</MuiLink>}
                    <Box sx={{mx: "0.4rem"}}></Box>
                    {!!reply && <MuiLink component={'button'} color={'appgrey.darker'} underline="none" sx={{fontSize: "0.8rem"}} onClick={() => setReply(0)}>{t("Cancel")}</MuiLink>}
                </Box>
                {!!reply && <Box sx={{marginBottom: "1rem"}}>
                    <OutlinedInput
                        fullWidth
                        placeholder={t("Reply...")}
                        multiline
                        type={'text'}
                        onClick={() => {
                            if(!isConnected() && !opened) {
                                actionAuth(() => {});
                            }
                        }}
                        onChange={(e) => setReplyText(e.target.value)}
                        value={replyText}
                        endAdornment={
                            user.id ? <InputAdornment position="end">
                                <IconButton onClick={replyComment}
                                    edge="end"
                                >
                                    {commenting ? <CircularProgress size={'1.6rem'} /> : <SendRounded />}
                                </IconButton>
                            </InputAdornment> : ''
                        }/>
                </Box>}
                {show && <>
                    {comment.replies?.map((r, i) => (
                        <CommentItem key={`comment-reply-${r.id}-${i + 1}`} presentation_id={presentation_id} user_id={user_id} comment={r}/>
                    ))}
                </>}
            </Box>
            {del && <Dialog
                open={del}
                onClose={() => setDel(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("Confirm?")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("Are you sure you want to delete this feedback")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={deleteComment} autoFocus>{t("Yes")}</Button>
                    <Button onClick={() => setDel(false)}>{t("No")}</Button>
                </DialogActions>
            </Dialog>}
        </Box>
    );
}

export default CommentItem;