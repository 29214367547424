import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import {CloudUploadOutlined, Close as CloseIcon} from '@mui/icons-material';
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next';
import { Alert, Avatar, Box, Card, CardContent, CircularProgress, Grid, Paper, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import {importFileandPreview, generateVideoThumbnails} from '../services/VideoService'
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth } from '../providers/AuthProvider';
import Moment from 'react-moment';
import ActivitySelect from './ActivitySelect';
import { Link } from 'react-router-dom';
import PresentationBox from './PresentationBox';
import VideoPlayer from './VideoPlayer';
import {b64toBlob} from '../helpers'
import { useApi } from '../providers/ApiProvider';
import LinearProgress from './LinearProgress'
import { Presentation } from '../modules';
import LoginModalContent from './LoginModalContent';
import SignupModalContent from './SignupModalContent';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export interface AuthModalProps {
    children?: React.ReactNode;
    onClose: () => void;
    onSuccess: () => void;
    open: boolean;
}

export default function AuthModal({onClose, onSuccess, open} : AuthModalProps) {
    const [type, setType] = React.useState<'login' | 'signup'>('login');
    const [created, setCreated] = React.useState(false);
    const { t } = useTranslation();
    const api = useApi();
    const {user} = useAuth();

    const closeModal = () => {
        onClose();
        setCreated(false);
        setType('login');
    }

    return (
        <BootstrapDialog onClose={closeModal} aria-labelledby="customized-dialog-title" open={open}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={closeModal}>{t('Login or Create an account')}</BootstrapDialogTitle>
            <DialogContent dividers>
                {type == 'login' && <LoginModalContent switchType={() => setType('signup')} created={created} onSuccess={onSuccess} />}
                {type == 'signup' && <SignupModalContent switchType={() => setType('login')} onCreated={(c) => setCreated(c)} />}
            </DialogContent>
        </BootstrapDialog>
    );
}