import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const DataEmptyBox = ({label} : {label?: string}) => {
    const { t } = useTranslation();
    return (
        <Box sx={{maxWidth: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "1rem", textAlign: "center"}}>
            <Box component={'img'} sx={{maxWidth: "10rem"}} src="/assets/img/kid-not-found.svg"></Box>
            <Box component={'h1'} sx={{color: "black"}}>{label ? label : t("Data not available")}</Box>
        </Box>
    )
}

export default DataEmptyBox;