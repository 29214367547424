import { forwardRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl, { FormControlClasses, FormControlPropsColorOverrides, FormControlPropsSizeOverrides, FormControlTypeMap } from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { OverridableStringUnion } from '@mui/types';
import { Theme } from "@emotion/react";
import { FormHelperText, SxProps } from "@mui/material";

interface PasswordControlProps {
    id?: string;
    classes?: Partial<FormControlClasses>;
    label?: React.ReactNode;
    color?: OverridableStringUnion<
      'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
      FormControlPropsColorOverrides
    >;
    disabled?: boolean;
    error?: boolean;
    fullWidth?: boolean;
    focused?: boolean;
    hiddenLabel?: boolean;
    margin?: 'dense' | 'normal' | 'none';
    required?: boolean;
    size?: OverridableStringUnion<'small' | 'medium', FormControlPropsSizeOverrides>;
    sx?: SxProps<Theme>;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    name?: string;
    value?: unknown;
    className?: string;
    helperText?: React.ReactNode;
}

const PasswordControl = (props: PasswordControlProps, ref: React.Ref<any>) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    return (
        <FormControl disabled={props.disabled} fullWidth={props.fullWidth} sx={props.sx} color={props.color} error={props.error} required={props.required} variant="outlined">
            <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
            <OutlinedInput ref={ref} value={props.value} name={props.name} onChange={props.onChange} onBlur={props.onBlur} id={props.id} type={showPassword ? "text" : "password"}
                endAdornment={
                <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                </InputAdornment>
                } label={props.label}
            />
            {!!props.error && (
                <FormHelperText error>{props.helperText}</FormHelperText>
            )}
        </FormControl>
    );
}

export default forwardRef(PasswordControl)