import reactStringReplace from 'react-string-replace';
import CryptoJS from 'crypto-js'
import { Avatar, Link, Typography } from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';

const SECRET_KEY = "kidkidland@2023"

export class ClientHTTP {

    [key: string]: any;

    protected browser() {
        var objappVersion = navigator?.appVersion;
        var browserAgent = navigator.userAgent;
        var browserName = navigator?.appName;
        var browserVersion = '' + parseFloat(navigator?.appVersion);
        var browserMajorVersion = parseInt(navigator?.appVersion, 10);
        var Offset, OffsetVersion, ix;
        
        if ((OffsetVersion = browserAgent.indexOf("Chrome")) != -1) {
            browserName = "Chrome";
            browserVersion = browserAgent.substring(OffsetVersion + 7);
        } else if ((OffsetVersion = browserAgent.indexOf("MSIE")) != -1) {
            browserName = "Microsoft Internet Explorer";
            browserVersion = browserAgent.substring(OffsetVersion + 5);
        } else if ((OffsetVersion = browserAgent.indexOf("Firefox")) != -1) {
            browserName = "Firefox";
        } else if ((OffsetVersion = browserAgent.indexOf("Safari")) != -1) {
            browserName = "Safari";
            browserVersion = browserAgent.substring(OffsetVersion + 7);
            if ((OffsetVersion = browserAgent.indexOf("Version")) != -1)
                browserVersion = browserAgent.substring(OffsetVersion + 8);
        } else if ((Offset = browserAgent.lastIndexOf(' ') + 1) <
            (OffsetVersion = browserAgent.lastIndexOf('/'))) {
            browserName = browserAgent.substring(Offset, OffsetVersion);
            browserVersion = browserAgent.substring(OffsetVersion + 1);
            if (browserName.toLowerCase() == browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        
        if ((ix = browserVersion.indexOf(";")) != -1)
            browserVersion = browserVersion.substring(0, ix);
        if ((ix = browserVersion.indexOf(" ")) != -1)
            browserVersion = browserVersion.substring(0, ix);
          
          
        browserMajorVersion = parseInt('' + browserVersion, 10);
        if (isNaN(browserMajorVersion)) {
            browserVersion = '' + parseFloat(navigator.appVersion);
            browserMajorVersion = parseInt(navigator.appVersion, 10);
        }

        return {
            objappVersion,
            browserAgent,
            browserName,
            browserVersion,
            browserMajorVersion,
            appName: navigator?.appName,
            language: navigator.language,
            languages: navigator.languages,
            platform: navigator?.platform || 'none',
            application: 'KidzBrand Platform v2023'
        };
    }
    
    constructor(data:any) {
        try {
            data = JSON.parse(data);
        } catch(e){}
        this.client = this.browser()
        this.request = data ? cryptData(data) : data
    }
}

export const b64toBlob = (b64Data:any, sliceSize=512) => {
    const parts = b64Data.split(',')
    let contentType = parts[0]?.replaceAll('data:', '')?.replaceAll(';base64', '')
    b64Data = parts[1]
    const byteCharacters = atob(b64Data);
    const byteArrays: Array<Uint8Array> = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

const DIV_REGEX = /\n/g;
const LINK_REGEX = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/g;
const MENTION_REGEX = /@([a-z\\-\d_]+)/ig;

export const parseStringHtml = (input:any) => {

    if( !input ) return <div></div>;

    input = reactStringReplace(input, LINK_REGEX, (match, i) => (
        <Link href={`${match}`} sx={{textDecoration: "none"}}>{match}</Link>
    ));

    input = reactStringReplace(input, DIV_REGEX, (match, i) => (
        <div></div>
    ));
    
    input = reactStringReplace(input, MENTION_REGEX, (match, i) => (
        <Link component={RouterLink} to={`/u/@${match}`} sx={{textDecoration: "none"}}>@{match}</Link>
    ));
    
    return input;
}

export const makeSek = ():string => {
    let k = localStorage.getItem('SEKID');
    if( !k ) {
        const code = makeCode(20);
        const hash = cryptData(code, code);
        localStorage.setItem('SEKID', hash);
        return hash;
    }

    return k;
}

export const makeCode = (length:number) => {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const decryptData = (data:any, key?:string) => {
    if( !key ) key = makeSek();
    if( typeof data == 'object' )
        data = data.join('')
    const enc = CryptoJS.AES.decrypt(data, key);
    if( enc ) {
        try {
            const str = enc.toString(CryptoJS.enc.Utf8);
            if( str.length > 0 ) {
                try{
                    return JSON.parse(str)
                } catch(e) {return str}
            }
        } catch (error) {}
    }
    return data;
}

export const cryptData = (data:any, key?:string):string => {
    if( !key ) key = makeSek();
    if( typeof data != 'string' )
        data = JSON.stringify(data);
    return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key).toString();
}