import { Autocomplete, AutocompleteProps, ChipTypeMap } from "@mui/material";
import { useApi } from "../providers/ApiProvider";
import { useEffect, useState } from "react";

type ActivitySelectProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>, "options">;

export default function ActivitySelect<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(props: ActivitySelectProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>) {
    const api = useApi();
    const [activities, setActivities] = useState<Array<Record<any, any>>>([]);

    useEffect(() => {
        api.getActivitiesCollection(true).then((acts) => {
            setActivities(acts)
        }).catch(() => {})
    }, [])
    return (
        <Autocomplete {...props} options={activities.map((activ) => activ.id)} getOptionLabel={(option) => activities.find((act) => act.id == option)?.name || ''}/>
    );
}