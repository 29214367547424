import { Avatar, Link, Typography } from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';

export interface AuthorLinkProps {
    slug: string;
    avatar?: string;
    name?: string;
}

const AuthorLink = ({avatar, slug, name}: AuthorLinkProps) => {
    return (
        <Link component={RouterLink} to={`/u/@${slug}`} sx={{fontSize: "0.8rem", paddingInlineStart: 0, display: "inline-flex", alignItems: "center", justifyContent: "center", position: "relative", textDecoration: "none", textTransform: "capitalize"}}>
            <Avatar sx={{width: 24, height: 24, fontSize: 16, marginInlineEnd: "0.4rem"}} alt={name} src={avatar}/>
            <Typography sx={{fontWeight: "800"}} variant="body2" color={'primary'}>{!!name ? name : `@${slug}`}</Typography>
        </Link>
    )
}

export default AuthorLink;