import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Palette, useTheme, PaletteColor } from '@mui/material/styles';
import { Link } from "@mui/material";

interface AppLinkProps extends RouterLinkProps {
}

const AppLink: React.FC<AppLinkProps> = (props: AppLinkProps) => {
    return (<Link component={RouterLink} {...props} underline={"none"} />);
}

export default AppLink;