import * as React from 'react';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Paper, FormLabel, SvgIcon, Box } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface BoxRadioProp {
    value?: any,
    label?: string,
    desc?: string,
    icon: React.ReactNode,
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const BoxRadio = (props: BoxRadioProp) => {
    const radioGroup = useRadioGroup();

    let checked = false;
    let name: string = props.value || Date.now().toString()

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }
    //<FormControlLabel checked={checked} {...props} />;

    return (
        <Paper
            variant="outlined"
            sx={{
                borderRadius: 'md',
                bgcolor: 'background.body',
                boxShadow: 'sm',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1.5,
                borderWidth: 2,
                cursor: 'pointer',
                borderColor: checked ? 'primary.main' : '#dddddd',
                flex: 1
            }}
            >
                <FormLabel sx={{ p: 2, cursor: 'pointer', width: "100%", textAlign: 'center'}} htmlFor={name}>
                    <Box sx={{}}>
                        <SvgIcon sx={{color: checked ? 'primary.main' : '#dddddd'}} children={props.icon}></SvgIcon>
                    </Box>
                    <Box component={'h4'}>{props.label}</Box>
                    <Box component={'p'}>{props.desc}</Box>
                    <Radio onChange={props.onChange} id={name} value={props.value} sx={{ display: 'none'}}
                    />
                </FormLabel>
        </Paper>
    )
}

export default BoxRadio;