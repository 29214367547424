import { Card, Box, CardContent, Typography, Button, Avatar, useTheme } from "@mui/material";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import i18n from "../services/TransService";
import { useEffect, useState } from "react";
import { Palette, PaletteColor } from "@mui/material/styles";
import { Presentation } from "../modules";


interface PresentationBoxProps {
    fullwidth?: boolean;
    preview?: boolean;
    image_url?: string;
    slug?: string;
    title?: string;
    description?: string;
    user?: Record<any, any>;
    created_at?: any;
}

const PresentationBox = ({fullwidth, image_url, slug, preview, title, description, user, created_at}: PresentationBoxProps) => {
    const theme = useTheme();
    const colors = {
        'primary': theme.palette.primary, 
        'secondary': theme.palette.secondary, 
        'greencolor': theme.palette.greencolor, 
        'bluecolor': theme.palette.bluecolor, 
        'redcolor': theme.palette.redcolor
    };

    const [color, setColor] = useState<PaletteColor>(theme.palette.primary);
    const [variant, setVariant] = useState<"primary" | "secondary" | "greencolor" | "bluecolor" | "redcolor" | "pinkone" | "purplecolor">('primary');

    useEffect(() => {
        const index = Math.floor(Math.random() * Object.keys(colors).length);
        setVariant(Object.keys(colors)[index] as "primary" | "secondary" | "greencolor" | "bluecolor" | "redcolor" | "pinkone" | "purplecolor");
        const chosen = Object.values(colors)[index];
        if( chosen ) setColor(chosen);
    }, []);

    return (
        <Card sx={{ maxWidth: fullwidth ? "100%" : 345, marginInline: 'auto', borderRadius: theme.borders?.primary, borderWidth: 1, borderColor: color.dark, borderStyle: 'solid', backgroundColor: color.lighter }}>
            <Box component={'div'} sx={{padding: "1rem", paddingBottom: 0}}>
                {preview && <Box component={'div'} sx={{...theme.aspectratio.root, backgroundColor: 'appgrey.light', borderRadius: theme.borders?.primary, borderWidth: 1, borderStyle: 'solid', borderColor: color.dark}}>
                    <Box component={'img'} src={image_url} sx={{...theme.aspectratio.child}}></Box>
                </Box>}
                {!preview && <Box component={Link} to={`/presentation/${slug}`} sx={{...theme.aspectratio.root, backgroundColor: 'appgrey.light', borderRadius: theme.borders?.primary, borderWidth: 1, borderStyle: 'solid', borderColor: color.dark, display: "block"}}>
                    <Box component={'img'} src={image_url} sx={{...theme.aspectratio.child}}></Box>
                </Box>}
            </Box>
            <CardContent>
                {preview && <Typography noWrap variant="h6" sx={{fontSize: "1rem", fontWeight: 'bold', color: 'black', display: "block"}}>{title}</Typography>}
                {!preview && <Typography noWrap component={Link} to={`/presentation/${slug}`} variant="h6" sx={{fontSize: "1rem", fontWeight: 'bold', color: 'black', textDecoration: "none", display: "block"}}>{title}</Typography>}
                {!!description && <Typography noWrap variant="body2" sx={{color: 'darkgrey'}}>{description}</Typography>}
                <Box component={'div'} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    {!preview && <Button disabled={preview} component={Link} to={`/u/@${user?.slug}`} sx={{fontSize: "0.8rem", paddingInlineStart: 0}}>
                        <Avatar sx={{width: 24, height: 24, fontSize: 16, marginInlineEnd: "0.4rem"}} alt={user?.name} src={user?.avatar}/>
                        <Typography variant="body2">{user?.name}</Typography>
                    </Button>}
                    {preview && <Button color={variant} sx={{fontSize: "0.8rem", paddingInlineStart: 0}}>
                        <Avatar sx={{width: 24, height: 24, fontSize: 16, marginInlineEnd: "0.4rem"}} alt={user?.name} src={user?.avatar}/>
                        <Typography variant="body2" sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{user?.name}</Typography>
                    </Button>}
                    <Box sx={{display: "block", width: "0.3rem", height: "0.3rem", borderRadius: "100%", backgroundColor: color.dark, marginInline: "0.4rem", flex: "0 0 auto"}}/>
                    <Moment style={{fontSize: "0.8rem", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} fromNow locale={i18n.language}>{created_at}</Moment>
                </Box>
            </CardContent>
        </Card>
    )
}

export default PresentationBox;