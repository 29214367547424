import { AxiosInstance } from 'axios';
const chunkSize = 1024 * 1024 * 5;

export const getVideoCover = (urlOfFIle: string, seekTo = 0.0): Promise<string> => {
    return new Promise((resolve, reject) => {
        try {
            // load the file to a video player
            const videoPlayer = document.createElement('video');
            // videoPlayer.setAttribute('src', URL.createObjectURL(urlOfFIle));
            videoPlayer.setAttribute('src', urlOfFIle);
            videoPlayer.crossOrigin = "Anonymous";
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                reject(`error when loading video file ${ex}`);
            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 200);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    // console.log('video is now paused at %ss.', seekTo);
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    ctx!.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    // return the canvas image as a blob
                    // then convert it to base 64
                    ctx!.canvas.toBlob(
                        blob => {
                            var reader = new FileReader();
                            reader.readAsDataURL(blob as Blob);
                            reader.onloadend = function () {
                                var base64data = reader.result;
                                resolve(base64data as string);
                            }
                        },
                        "image/jpeg",
                        1 /* quality */
                    );
                });
            });
        } catch (error) {
            reject(error);
        }
    });
}
export const getVideoThumbnail = (file: File | string, videoTimeInSeconds: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        if ((file as File)?.type?.match("video")) {
            importFileandPreview(file as File).then((urlOfFIle) => {
                getVideoCover(urlOfFIle, videoTimeInSeconds).then((res) => {
                    resolve(res);
                })
            });
        } else if (file) {
            getVideoCover(file as string, videoTimeInSeconds).then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err);
            })
        }
        else {
            reject("file not valid");
        }
    });
};

export const importFileandPreview = (file: File, revoke?: boolean): Promise<string> => {
    return new Promise((resolve, reject) => {
        window.URL = window.URL || window.webkitURL;
        let preview = window.URL.createObjectURL(file);
        if( !preview ) {
            reject()
        }
        // remove reference
        if (revoke) {
            window.URL.revokeObjectURL(preview);
        }
        setTimeout(() => {
            resolve(preview);
        }, 100);
    });
};

export const generateVideoThumbnails = async (videoFile: File, numberOfThumbnails: number, type: string, cb?: (thumbnail: string, index: number) => void): Promise<string[]> => {
    let thumbnail: string[] = [];
    let fractions: number[] = [];

    return new Promise(async (resolve, reject) => {        
        if (type === "file") {
          if (!videoFile.type?.includes("video")) {
            reject("not a valid video file");
          }
        }
        
        await getVideoDurationFromVideoFile(videoFile).then(async (duration) => {
            // divide the video timing into particular timestamps in respective to number of thumbnails
            // ex if time is 10 and numOfthumbnails is 4 then result will be -> 0, 2.5, 5, 7.5 ,10
            // we will use this timestamp to take snapshots
            for (let i = 0; i <= duration; i += duration / numberOfThumbnails) {
                fractions.push(Math.floor(i));
            }

            let promiseArray = fractions.map(async (time, index) => {
              const res = await getVideoThumbnail(
                videoFile,
                index >= fractions.length - 1 ? time - 2 : time
              );

              if (cb) cb(res, index);
              return Promise.resolve(res);
            });

            await Promise.all(promiseArray)
              .then((res) => {
                res.forEach((res) => {
                  thumbnail.push(res);
                });
                resolve(thumbnail);
              })
              .catch((err) => {
                reject(err);
              })
              .finally(() => resolve(thumbnail));
        }).catch((err) => {
            reject(err);
        })
        reject("something went wrong");
    });
};

export const getVideoDurationFromVideoFile = (videoFile: File | string): Promise<number> => {
    return new Promise((resolve, reject) => {
        try {
            if (videoFile) {
                if ((videoFile as File)?.type?.match("video")) {
                    importFileandPreview(videoFile as File).then((url) => {
                        generateVideoDurationFromUrl(url).then((res) => {
                            resolve(res);
                        })
                    });
                } else {
                    generateVideoDurationFromUrl(videoFile as string).then((res) => {
                        resolve(res)
                    })
                }
            } else {
                reject("Cannot generate video duration for this video file.");
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const generateVideoDurationFromUrl = (url: string): Promise<number> => {
    return new Promise((resolve, reject) => {
        let video = document.createElement("video");
        video.addEventListener("loadeddata", function () {
            resolve(video.duration);
            window.URL.revokeObjectURL(url);
        });
        video.preload = "metadata";
        video.src = url;
        // Load video in Safari / IE11
        video.muted = true;
        video.crossOrigin = "Anonymous";
        video.playsInline = true;
        video.play();
    })
}

export const chunkUploader:any = (api:AxiosInstance, endpoint:string, file:File, options:any) => {
    const blockCount = Math.ceil(file.size / chunkSize);
    const start = options.chunkNumber * chunkSize;
    const end = Math.min(file.size, start + chunkSize);
    let currentChunkSize = chunkSize;
    if (options.chunkNumber + 1 === options.blockCount) {
        currentChunkSize = file.size - start;
    }

    const params = new FormData();
    params.append('resumableChunkNumber', options.chunkNumber + 1);
    params.append('resumableChunkSize', currentChunkSize.toString());
    params.append('resumableCurrentChunkSize', currentChunkSize.toString());
    params.append('resumableTotalSize', file.size.toString());
    params.append('resumableType', file.type);
    params.append('resumableIdentifier', options.identifier);
    params.append('resumableFilename', file.name);
    params.append('resumableRelativePath', file.name);
    params.append('resumableTotalChunks', options.blockCount);
    params.append('video', file.slice(start, end), file.name);

    return api.post(endpoint, params, {
        onUploadProgress(progressEvent:any) {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            let fileper = parseInt(`${end / file.size * 100}`, 10) - options.progress;
            let current = parseInt(`${(fileper * percent) / 100}`) + options.progress
            options.onProgress && options.onProgress(current);
        },
    })
    .then(res => {
        //options.progress = parseInt(`${end / file.size * 100}`, 10);
        if (end === file.size) {
            options.onSuccess && options.onSuccess(res?.data);
        } else {
            options.chunkNumber = options.chunkNumber + 1;
            return chunkUploader(endpoint, file, options);
        }
    }).catch(err => {
        options.onError && options.onError(err?.response?.data ? err?.response?.data : err )
    });
};

export const chunkUpload = async (api:AxiosInstance, endpoint:string, file:File, options:any) => {
    let chunkSize = 5 * 1024 * 1024; // 5 MB chunk size
    let chunks = [];
    let fileSize = file.size;
    let start = 0;
    let end = chunkSize;
    let tries = 3;
    const blockCount = Math.ceil(file.size / chunkSize);
    const identifier = `${file.size}-${file.name.replace('.', '')}`;

    while (start < fileSize) {
        chunks.push(file.slice(start, end));
        start = end;
        end = start + chunkSize;
    }
    
    let uploadPromises: Promise<any>[] = [];

    for(let index = 0; index < chunks.length; index++) {
        const chunk = chunks[index];
        const params = new FormData();
        params.append('resumableChunkNumber', `${index + 1}`);
        params.append('resumableChunkSize', chunkSize.toString());
        params.append('resumableCurrentChunkSize', chunk.size.toString());
        params.append('resumableTotalSize', file.size.toString());
        params.append('resumableType', file.type);
        params.append('resumableIdentifier', identifier);
        params.append('resumableFilename', file.name);
        params.append('resumableRelativePath', file.name);
        params.append('resumableTotalChunks', blockCount.toString());
        params.append('video', chunk, file.name);

        await api.post(endpoint, params, {
            onUploadProgress(progressEvent:any) {
                const { loaded, total } = progressEvent;
                let current = loaded + (chunkSize * index)
                let percent = Math.floor((current * 100) / file.size);
                options.onProgress && options.onProgress(percent);
            },
        }).then(response => {
            if (index == chunks.length - 1) {
                options.onSuccess && options.onSuccess(response?.data);
            }
        })
        .catch(err => {
            if( tries > 0 ) {
                tries = tries - 1;
                index = index - 1;
            } else {
                options.onError && options.onError(err?.response?.data ? err?.response?.data : err )
            }
        })
    };
}
export const chunk = (api:AxiosInstance, endpoint:string, file:File, onProgress:any, onError:any, onSuccess:any) => {
    const blockCount = Math.ceil(file.size / chunkSize);
    const chunkNumber = 0;
    const identifier = `${file.size}-${file.name.replace('.', '')}`;
    return chunkUpload(api, endpoint, file, {
        progress: 0,
        blockCount,
        identifier,
        chunkNumber,
        onProgress,
        onError,
        onSuccess
    });
}