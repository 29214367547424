import { forwardRef, useRef } from "react";
import Carousel, {CarouselProps} from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const AppCarousel = forwardRef<Carousel, CarouselProps>((props: CarouselProps, ref) => {
    return (
        <Carousel ref={ref} {...props}/>
    )
});

export default AppCarousel;