import { VideoLibrary, Bookmarks } from "@mui/icons-material";

// constant
const icons = { VideoLibrary, Bookmarks };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const me = {
  id: 'me-pages',
  type: 'group',
  children: [
    {
      id: 'me',
      title: 'My presentations',
      type: 'item',
      url: '/me',
      icon: icons.VideoLibrary
    },
    {
      id: 'library',
      title: 'Library',
      type: 'item',
      url: '/me/library',
      icon: icons.Bookmarks
    }
  ]
};

export default me;