import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import './App.css';
import { AuthProvider } from './providers/AuthProvider';
import { AppLayout } from './layout/AppLayout';
import { AuthLayout } from './layout/AuthLayout';
import ThemeProvider from './theme/ThemeProvider';
import { Login } from './views/Login';
import { Signup } from './views/Signup';
import { Home } from './views/Home';
import { Schools } from './views/Schools';
import { Library } from "./views/Library";
import { Profile } from "./views/Profile";
import { Presentation } from "./views/Presentation";
import { Activity } from "./views/Activity";
import { Author } from "./views/Author";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { RequireAuth } from "./components/RequireAuth";
import { ResetPassword } from "./views/ResetPassword";

function App() {
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  
  return (
    <ThemeProvider>
      <AuthProvider>
        <Helmet>
          <title>{t('KizBrand')}</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="theme-color" content={theme.palette.primary.main}/>
        </Helmet>
        <Routes>
          <Route path="/" element={<AppLayout/>}>
            <Route path="" element={<Home/>} />
            <Route path="/@schools" element={<Schools />} />
            <Route path="/presentation/:slug" element={<Presentation />} />
            <Route path="/activity/:slug" element={<Activity />} />
            <Route path="/u/:slug" element={<Author />} />
            <Route path="/me/:tab?" element={<RequireAuth><Profile/></RequireAuth>} />
          </Route>
          <Route path='auth' element={<AuthLayout/>}>
            <Route path="" element={<Login/>} />
            <Route path="resetpassword" element={<ResetPassword/>} />
            <Route path="login" element={<Login/>} />
            <Route path="signup" element={<Signup/>} />
          </Route>
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
