import React from "react";
import { RequireAuth } from "../components/RequireAuth";

export const Activity = () => {
    
    return (
        <>
            <>Activity</>
        </>
    );
}