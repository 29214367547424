import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { Header } from "../components/AppLayout/Header";
import { Sidebar } from "../components/AppLayout/SideBar";
import { SidebarProvider } from "../providers/SidebarProvider";
import { Main } from "../components/AppLayout/Main";
import { UploadProvider } from "../providers/UploadProvider";
import bg from '../assets/img/scattered-forcefields.svg'

export const AppLayout = () => {

  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <UploadProvider>
        <SidebarProvider>
          <Box sx={{ display: 'flex', background: `linear-gradient(to bottom, ${theme.palette.primary.lighter}, ${theme.palette.secondary.light})` }}>
            <CssBaseline />
            <Header/>
            <Sidebar />
            <Main>
              <Outlet />
            </Main>
          </Box>
        </SidebarProvider>
      </UploadProvider>
    </>
  );
}