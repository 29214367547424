import { useTheme } from "@mui/material/styles";
import { Box, Button, Chip, Drawer, Stack, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from 'react-perfect-scrollbar';
import MenuList from "./MenuList";
import { drawerWidth } from "../../store/constant";
import { useSidebar } from "../../providers/SidebarProvider";
import { useTranslation } from "react-i18next";
import { useUpload } from "../../providers/UploadProvider";

const AddPresentationBox = ({open}: {open: () => void}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{border: `1px solid ${theme.palette.primary.dark}`, borderRadius: theme.borders.primary, padding: "2rem"}}>
                <Box sx={{width: "100%", maxWidth: 120, marginInline: "auto", display: "block", marginBottom: "1rem"}} component={'img'} src="/assets/img/kid-watching.svg"></Box>
                <Button fullWidth color="primary" variant="outlined" onClick={open}>{t('Add presentation')}</Button>
            </Box>
        </>
    );
}

export const Sidebar = () => {

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const {sidebarToggle, toggleSidebar, closeSidebar} = useSidebar();
    const { t } = useTranslation();
    const {openUpload} = useUpload();

    const drawer = (
      <>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                <Link to={'/'} onClick={closeSidebar}><img style={{width: 120}} src="/assets/img/kids-presentation-logo.png" alt="" /></Link>
            </Box>
        </Box>
        <BrowserView>
            <PerfectScrollbar component="div" style={{ height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)', paddingLeft: '16px', paddingRight: '16px' }}>
                <MenuList />
                <AddPresentationBox open={openUpload}/>
            </PerfectScrollbar>
        </BrowserView>
        <MobileView>
            <Box sx={{ px: 2 }}>
                <MenuList />
                <AddPresentationBox open={openUpload}/>
            </Box>
        </MobileView>
      </>
    );
  
    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={sidebarToggle}
                onClose={toggleSidebar}
                sx={{
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    background: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    borderRight: 'none',
                    [theme.breakpoints.up('md')]: {
                        top: '64px'
                    }
                }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >{drawer}</Drawer>
        </Box>
    );
}