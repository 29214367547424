import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { useEffect } from "react";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
    let {user} = useAuth();
    let location = useLocation();
    let navigate = useNavigate();
    
    useEffect(() => {
        if ( !user?.id ) {
            navigate('/auth/login', {state: {from: location}, replace: true})
            //return <></>
        }
    }, [user])

    return children;
}