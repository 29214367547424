import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useApi } from "../providers/ApiProvider";
import { Presentation as Model } from "../modules";
import { Box, Card, Grid, Button, Typography, useTheme, Avatar, Collapse, TextField, Divider } from "@mui/material";
import VideoPlayer from "../components/VideoPlayer";
import { BookmarkAddOutlined, BookmarkRounded, ChatBubbleOutlineRounded, FavoriteBorderOutlined, FavoriteRounded, VisibilityOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import PresentationBox from "../components/PresentationBox";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../providers/AuthProvider";
import PresentationItem from "../components/PresentationItem";
import Moment from "react-moment";
import { parseStringHtml } from "../helpers";
import bg1 from '../assets/img/kids-bg-cool-up.svg'
import bg2 from '../assets/img/kids-bg-cool-down.svg'
import AuthorLink from "../components/AuthorLink";
import CommentSection from "../components/CommentSection";
import DataEmptyBox from "../components/DataEmptyBox";

export const Presentation = () => {
    const {actionAuth, user, isConnected} = useAuth();
    const [presentations, setPresentations] = useState<Array<Model>>([]);
    const [presentation, setPresentation] = useState<Model>({} as Model);
    const [collapsed, setCollapsed] = useState(true);
    const [loading, setLoading] = useState(false);
    const [voted, setVoted] = useState(false);
    const [saved, setSaved] = useState(false);
    const [votes, setVotes] = useState(0);
    const [saves, setSaves] = useState(0);
    const [watch, setWatch] = useState({
        step: -1,
        logging: false
    });
    const { t, i18n } = useTranslation();
    const params = useParams();
    const api = useApi();
    const theme = useTheme();
    
    const fetchActivityPresentations = (id:any) => {
        api.getActivityPresentations(id).then((data) => {
            setPresentations(data.data);
        }).catch(() => {

        });
    }

    const care = () => {
        actionAuth(() => {
            console.log(voted)
            setVoted(!voted);
            api.votePresentation(presentation.id).then((counts: number) => {
                setVotes(counts);
                presentation.votecount = counts;
            }).catch(() => setVoted(!voted))
        });
    }

    const save = () => {
        actionAuth(() => {
            console.log(saved)
            setSaved(!saved);
            api.savePresentation(presentation.id).then((counts: number) => {
                setSaves(counts);
                presentation.savecount = counts;
            }).catch(() => setSaved(!saved))
        });
    }

    const timeUpdate = (time: number, duration: number, id?: any) => {
        if( !isConnected() ) {
            return;
        }
        if (watch.logging) return;
        let valide = (20 * duration) / 100
        let steps = Array.from(
            { length: (duration - valide) / valide + 1 },
            (value, index) => valide + index * valide
        );
        if (watch.step == -1) {
            let _valide = Math.round(valide > 30 ? 30 : valide);
            let _time = Math.round(time);
            if (_time >= _valide) {
                watch.logging = true;
                api.logPresentation(id || presentation.id, 'view', _valide).then((data: any) => {
                    watch.step = 0;
                }).catch(() => {}).finally(() => watch.logging = false)
            }
        } else if (duration == time) {
            watch.logging = true;
            api.logPresentation(id || presentation.id, 'watchEnd', time).then((data: any) => {
                watch.step = 0;
            }).catch(() => {}).finally(() => watch.logging = false)
        } else if (steps[watch.step] && time >= steps[watch.step]) {
            watch.logging = true;
            api.logPresentation(id || presentation.id, 'watch', time).then((data: any) => {
                watch.step = watch.step + 1;
            }).catch(() => {}).finally(() => watch.logging = false)
        }
    }

    const onReady = (player: any) => {
        if( player ) {
            player.on('timeupdate', (e:any) => {
                timeUpdate(player.currentTime(), player.duration());
            })
        }
    }

    useEffect(() => {
        setVoted(!!presentation.votes?.find((v) => v.user_id == user?.id)?.user_id)
        setSaved(!!presentation.saves?.find((v) => v.user_id == user?.id)?.user_id)
        setVotes(presentation.votes?.length || 0)
        setSaves(presentation.saves?.length || 0)
    }, [presentation, user]);

    useEffect(() => {
        setLoading(true);
        api.getPresentation(params.slug).then((data) => {
            setPresentation(data);
            fetchActivityPresentations(data.activity_id);
        }).catch(() => {

        }).finally(() => setLoading(false));
    }, [params.slug]);

    if( !loading && !presentation.id ) {
        return <DataEmptyBox label={t("This presentation not exists")} />;
    }

    return (
        <>
            <Helmet>
                <title>{[presentation.title, t('KidzBrand')].join(' - ')}</title>
            </Helmet>
            <Box sx={{width: "100%", backgroundImage: `url(${bg1}), url(${bg2})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'top center, bottom center', backgroundSize: '100% auto, 100% auto'}}>
                <Box sx={{maxWidth: "82rem", marginInline: "auto"}}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem"}}>
                                <Box sx={{overflow: "hidden", borderRadius: theme.borders.primary}}>
                                    {!!presentation.id && <VideoPlayer onReady={onReady} options={{autoplay: true, poster: presentation.image_url, aspectRatio: "16:9"}} src={presentation.video_url} />}
                                </Box>
                                <Box sx={{marginTop: "1rem"}}>
                                    <Typography variant="h6" sx={{fontSize: "1rem", fontWeight: 'bold', color: 'black'}}>{presentation.title}</Typography>
                                </Box>
                                <Box sx={{marginBottom: "1rem"}}>
                                    {!!presentation?.user && <AuthorLink {...presentation?.user}/>}
                                </Box>
                                <Box sx={{display: "flex", flexDirection: "row"}}>
                                    <Button onClick={care} variant="text" startIcon={voted ? <FavoriteRounded /> : <FavoriteBorderOutlined />}>{t('Care')}</Button>
                                    <Button onClick={save} variant="text" startIcon={saved ? <BookmarkRounded /> : <BookmarkAddOutlined />}>{t('Save')}</Button>
                                </Box>
                            </Card>
                            <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem"}}>
                                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "1rem"}}>
                                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <VisibilityOutlined sx={{fontSize: "0.8rem", marginInlineEnd: "0.4rem"}} />
                                        <Typography sx={{fontSize: "0.8rem"}}>{presentation.views} {t("views")}</Typography>
                                    </Box>
                                    <Box sx={{display: "block", width: "0.3rem", height: "0.3rem", borderRadius: "100%", backgroundColor: "primary.dark", marginInline: "0.4rem", flex: "0 0 auto"}}/>
                                    <Moment style={{fontSize: "0.8rem"}} fromNow locale={i18n.language}>{presentation.created_at}</Moment>
                                </Box>
                                <Collapse in={!collapsed} collapsedSize={40} >
                                    {parseStringHtml(presentation.description)}
                                </Collapse>
                                <Box>
                                    <Button onClick={() => setCollapsed((value) => !value)}>{collapsed ? t('More') : t('Less')}</Button>
                                </Box>
                            </Card>
                            {!!presentation.id && <CommentSection key={`comment-section-${presentation.id}`} user_id={presentation.user?.id} presentation_id={presentation.id} />}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "2rem", borderRadius: theme.borders.primary}}>
                            <Typography variant="h6" sx={{marginBottom: "1rem", fontWeight: "900"}} color={'primary.dark'} children={t('Related presentations')}/>
                            {presentations.filter(p => p.id != presentation.id).map((presentation, index) => (
                                <Box key={index} sx={{marginBottom: "1rem"}}>
                                    <PresentationItem row {...presentation}/>
                                </Box>
                            ))}
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}